
.containerWrapper{
    position: relative;
    margin: auto;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    border:1px solid #ccc !important;
    
  }
  
  .containerWrapper .overlay{
    background: linear-gradient(90deg, hsla(200, 73%, 52%, 1) 44%, hsla(220, 60%, 37%, 1) 100%);
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: .3s ease;
    transition: .3s ease;
  }
  
  .containerWrapper:hover .overlay{
    opacity: 1;
    width: 100%;
    height: 100%;
  }
  
  .overlay p{
    color: #fff;
    position: absolute;
    margin: auto;
    margin:10px 1px;
    left: 20px;
    top: 6rem;
    right: 10px;
    bottom: 0;
    line-height: 25px;
    transition: transformY(337px);
  
  }
  
  .containerWrapper:hover .overlay span{
    opacity: 1;
    -webkit-transition: 1.3s ease;
    transition: 1.3s ease;
  }