/* argin-top: 5rem; */

.each-fade {
  height: 90vh;
  width: 100vw;
}

/* .overlay {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.98),
    rgba(0, 0, 0, 0.55),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
  position: absolute;
  top: 0;
  left: 0;
} */

.welcome {
  position: absolute;
  font-family: "Poppins", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 3em;
  /* transform: translate(-50%, -50%); */
  left: 0em;
  top: 0em;
}

.welcome span {
  display: inline-block;
  z-index: 10;
  color: white;
  transform: translateY(-6vh);
  animation: fall 1s forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.to {
  position: absolute;
  font-family: "Poppins", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 3em;
  /* transform: translate(-50%, -50%); */
  left: 5.5em;
  top: 0em;
  z-index: 10;
  transform: translateX(10vw);
  animation: slideIn 1s forwards;
  animation-delay: 0.9s;
  opacity: 0;
}

.senwell {
  position: absolute;
  font-family: "Poppins", Geneva, Tahoma, sans-serif;
  letter-spacing: -3px;
  font-weight: 400;
  font-size: 5em;
  /* transform: translate(-50%, -50%); */
  left: 0em;
  top: 0.65em;
}

.senwell span {
  display: inline-block;
  z-index: 10;
  color: white;
  animation: title 1s forwards;
  animation-delay: 1s;
  opacity: 0;
}

.solutions {
  position: absolute;
  font-family: "Poppins", Geneva, Tahoma, sans-serif;
  letter-spacing: -3px;
  font-weight: 400;
  font-size: 5em;
  /* transform: translate(-50%, -50%); */
  left: 0em;
  top: 1.5em;
}

.solutions span {
  display: inline-block;
  z-index: 10;
  color: white;
  animation: title 1s forwards;
  animation-delay: 1.2s;
  opacity: 0;
}

.subtitle {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Poppins", Geneva, Tahoma, sans-serif;
  /* letter-spacing: -3px; */
  overflow: hidden;
  font-weight: 400;
  font-size: 1.5em;
  width: 25rem;
  height: 1.2em;
  transform: translate(-50%, -50%);
  transform: translateX(-10vw);
  left: 0em;
  top: 9.5em;
  animation: slideIn 2s forwards;
  animation-delay: 2.5s;
  opacity: 0;
}
.heading {
  position: absolute;
  top: 7rem;
  left: 2rem;
  height: 65vh;
  width: 85vw;
  margin: auto;
}

.subtitle > h4 {
  top: 0;
  left: 0em;
  font-weight: 400;
  margin: 0 !important;
}

.subtitle > span {
  position: absolute;
  display: block;
  /* overflow: hidden; */
  top: -0.4rem;
  left: 7.4em;
  height: 1.5em;
  color: rgb(170, 238, 255);
  font-weight: 600;
  font-size: 1.6rem;
  font-family: "Ubuntu";
  animation: scrollUp 4.6s infinite;
  animation-delay: 4.5s;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.btn {
  position: absolute;
  top: 18rem;
  left: 0;
  opacity: 0;
  animation: fadeIn 3s forwards;
  animation-delay: 3s;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  /* .overlay {
    margin-top: 0.5rem;
    height: 95vh !important;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.45),
      rgba(0, 0, 0, 0.2)
    );
  } */

  .each-fade > image {
    margin-left: 0 !important;
    margin-top: 4.5rem !important;
    height: 87vh !important;
  }

  .heading {
    width: 85vw;
    top: 17em;
  }

  /* .overlay {
    height: 100vh;
  } */

  .each-fade {
    height: 95vh;
  }
}

@media only screen and (max-width: 375px) {
  /* .overlay {
    height: 100vh;
    background: rgba(0, 0, 0, 0.45);
  } */

  .each-fade {
    height: 95vh;
  }

  .heading {
    top: 20vh !important;
    left: 0;
    width: 80vh;
  }
  .subtitle > span {
    letter-spacing: 1px !important;
  }
}

/* @media only screen and (max-width: 750px) {
  .heading{
    top: 17rem;
    left: 0;
    width: 90vw;
  }
  .each-fade {
    height: 100vh;
  }

  .each-fade > image {
    margin-top: 4.5em;
    width: 90vw;
    height: 100vh;
    /* margin-top: 0; */
/* margin-left: 2rem; */
/* }

  .overlay {
    height: 100vh;
  }
} */

@media only screen and (max-width: 420px) {
  .heading {
    top: 30vh;
    left: 0;
    width: 85vw;
  }

  .welcome {
    font-size: 2rem;
  }
  .to {
    font-size: 2rem;
  }
  .senwell {
    font-size: 3.5rem;
    letter-spacing: 0.5px;
  }
  .solutions {
    font-size: 3.5rem;
    letter-spacing: 0.5px;
  }
  .subtitle {
    top: 8.5em;
    font-size: 1.2em;
    width: 20.5rem;
  }
  .subtitle > span {
    font-size: 1.1em;
    letter-spacing: 1px;
    height: 1.2em;
    top: -5px;
    left: 8.2em;
    font-weight: 400;
  }
  .btn {
    top: 15rem;
    transform: scale(0.85);
    left: -1rem;
  }

  .each-fade {
    height: 95vh;
  }

  /* .overlay {
    height: 100vh;
    margin-top: 1.5rem;
    background: rgba(0, 0, 0, 0.45);
  } */
}

/*------------------------S-P-A-N-S--------------------------*/

.welcome span:nth-child(2) {
  animation-delay: 0.3s;
}
.welcome span:nth-child(3) {
  animation-delay: 0.4s;
}
.welcome span:nth-child(4) {
  animation-delay: 0.5s;
}
.welcome span:nth-child(5) {
  animation-delay: 0.6s;
}
.welcome span:nth-child(6) {
  animation-delay: 0.7s;
}
.welcome span:nth-child(7) {
  animation-delay: 0.8s;
}

.senwell span:nth-child(2) {
  animation-delay: 1.3s;
}
.senwell span:nth-child(3) {
  animation-delay: 1.5s;
}
.senwell span:nth-child(4) {
  animation-delay: 1.7s;
}
.senwell span:nth-child(5) {
  animation-delay: 1.9s;
}
.senwell span:nth-child(6) {
  animation-delay: 2.1s;
}
.senwell span:nth-child(7) {
  animation-delay: 2.3s;
}

.solutions span:nth-child(2) {
  animation-delay: 1.3s;
}
.solutions span:nth-child(3) {
  animation-delay: 1.4s;
}
.solutions span:nth-child(4) {
  animation-delay: 1.6s;
}
.solutions span:nth-child(5) {
  animation-delay: 1.8s;
}
.solutions span:nth-child(6) {
  animation-delay: 2s;
}
.solutions span:nth-child(7) {
  animation-delay: 2.2s;
}
.solutions span:nth-child(8) {
  animation-delay: 2.4s;
}
.solutions span:nth-child(9) {
  animation-delay: 2.6s;
}

/*------------------------S-P-A-N-S--------------------------*/

@keyframes fall {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideIn {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes title {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes scrollUp {
  0% {
    top: -0.4rem;
    animation-delay: 1s;
  }
  25% {
    top: -1.8em;
    animation-delay: 0.2s;
  }
  50% {
    top: -3.4em;
    animation-delay: 0.2s;
  }
  75% {
    top: -5em;
    animation-delay: 0.2s;
  }
}

@keyframes slideIn {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
