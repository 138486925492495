/* New Design    */

.content-block {
  padding: 21px 24px;
}

.content-block p {
  text-align: justify;
}

.card-first {
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
  width: 95%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.serviceWeOffer {
  color: #0b2e58;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 1.4;
  letter-spacing: -0.4px;
  font-size: 3rem;
  font-family: Manrope, sans-serif;
  cursor: default;
}

.serviceWeOffer-para {
  color: #333;
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 1.8;
  font-family: Manrope, sans-serif;
  cursor: default;
}

.services-subheader {
  font-size: 20px;
  line-height: 1.38;
  color: #0b2e58;
  font-weight: 600;
  margin-bottom: 8px;
  font-family: "Manrope", sans-serif;
  letter-spacing: -0.2px;
  text-decoration: none !important;
}

.Services-Para {
  font-size: 16px;
  line-height: 1.62;
  color: #333;
  font-family: "Manrope", sans-serif;
  text-decoration: none !important;
}

/* New */
.card-first img {
  width: 100%;
  transition: 0.8s all ease-in-out;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
}

.card-first:hover img {
  transform: scale(1.1);
}

@media(max-width:480px) {
  .serviceWeOffer {
    font-size: 2rem;
    font-weight: 600;
  }

  .serviceWeOffer-para {
    font-size: 1.1rem !important;
    line-height: 1.2 !important;
  }

  .Services-Para {
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
  }
}