/* .first-image{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
} */
.Annual-Sports{
    width: 95%;
}

.second-img {
    width: 100%;
  }
  .video-container .insideif {
    width: 100%;
    height: 100%; 
  }

.Sports-Day{
    padding: 20px 0px 10px 0px;
    font-size: 34px;
}
.Learning-P{
    padding: 20px 0px;
    margin-bottom: 30px;
}
.senwell-Kitli{
    padding: 9px 0px 0px 0px;
}

.insideif {
    width: 500px;
    height: 315px;
  }

  .video-container {
    width: 500px;
    height: 315px;
  }

@media(max-width:768px){

    .insideif {
        width: 100%; 
        height: 300px; 
      }
     .youtube-video .insideif{
         height: 300px !important;
     }

     .Sports-Day{
        margin-top: 100px;
     }

     .col-lg-5 {
        width: 100%;
      }
} 

@media(max-width:480px){
    .insideif {
        width: 100%; 
        height: 250px; 
      }
    .youtube-video .insideif{
        height: 250px !important;
    }
    .Sports-Day{
        margin-top: 150px;
     }
}

@media(max-width:380px){
    .insideif {
        width: 100%; 
        height: 200px; 
      }

    .youtube-video .insideif{
        height: 100% !important;
    }
    .Sports-Day{
        margin-top: 200px;
        text-align: center;
     }
     .Annual-Sports{
        text-align: justify;
        font-size: 15px;
        word-spacing: -0.1em;
        hyphens: auto;
        line-height: 21px;
     }
     .Learning-P{
        font-weight: 500;
        padding: 28px 0px 10px 0px !important;
        font-size: 19px;
     }
     .senwell-Kitli{
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
     }
}