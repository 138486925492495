/* General hero section styling */
.hero {
    position: relative;
    overflow: hidden;
}



.carousel-inner{
    position: relative;
}


/* Snowflake styles */
.snow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.snow.foreground,
.snow.middleground,
.snow.background {
    z-index: 999;
    overflow: hidden;
}

@keyframes snowflakes-fall {
    0% {
        top: -10%;
    }

    100% {
        top: 100%;
    }
}

.snowflake {
    position: absolute;
    top: -10%;
    z-index: 999;
    width: 0.2em;
    height: 0.2em;
    background: white;
    border-radius: 50%;
    opacity: 0.8;
    pointer-events: none;
    animation: snowflakes-fall 10s linear infinite
}

.snow.foreground .snowflake {
    animation-duration: 10s, 2s;
}

.snow.middleground .snowflake {
    animation-duration: 7s, 3s;
}

.snow.background .snowflake {
    animation-duration: 10s, 5s;
}

/* Carousel image styling */
.carousel-image {
    margin-top: 86px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    filter: opacity(0.99);
}

/* Button styling */
.SN-view {
    color: #fff;
    background-color: #00a9b3;
    border-color: #00a9b3;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    border: 1px solid transparent;
    padding: 18px;
    font-size: 1rem;
    box-shadow: 0 30px 40px -18px rgba(36, 144, 239, .3);
    border-radius: 6px;
}

.SN-view:hover {
    color: #fff;
}

/* Carousel caption */
.carousel-caption {
    position: absolute !important;
    top: 136px;
    right: -39% !important;
    bottom: 1.25rem !important;
    left: 15% !important;
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
    color: #fff !important;
    text-align: center !important;
}
/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
   
    
    .snowflake {
        width: 0.2em !important;
        height: 0.2em !important;
    }
    /* .snowflake {
        width: 0.12em !important;
        height: 0.12em !important;
    } */

    .SN-view {
        color: #fff;
        background-color: #00a9b3;
        border-color: #00a9b3;
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        border: 1px solid transparent;
        padding: 5px;
        font-size: 9px;
    }
}
@media (min-width: 1024px) {
    
    .carousel-image {
        margin-top: 101px;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
        filter: opacity(0.99);
    }
    
    .snowflake {
        width: 0.4em !important;
        height: 0.4em !important;
    }


    .SN-view {
        color: #fff;
        background-color: #00a9b3;
        border-color: #00a9b3;
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        border: 1px solid transparent;
        padding: 5px;
        font-size: 9px;
    }
}
@media (min-width: 2560px) {
    
    .carousel-image {
        margin-top: 101px;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
        filter: opacity(0.99);
    }
    
    .snowflake {
        width: 0.7em !important;
        height: 0.7em !important;
    }


    .SN-view {
        color: #fff;
        background-color: #00a9b3;
        border-color: #00a9b3;
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        border: 1px solid transparent;
        padding: 5px;
        font-size: 9px;
    }
}
@media (min-width: 1440px) {
    
    .carousel-image {
        margin-top:87px;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
        filter: opacity(0.99);
    }
    
    .snowflake {
        width: 0.4em !important;
        height: 0.4em !important;
    }


    .SN-view {
        color: #fff;
        background-color: #00a9b3;
        border-color: #00a9b3;
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        border: 1px solid transparent;
        padding: 5px;
        font-size: 9px;
    }
}
@media (max-width: 460px) {
    
    .carousel-image {
        margin-top: 85px;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
        filter: opacity(0.99);
    }
    
    .snowflake {
        width: 0.1em !important;
        height: 0.1em !important;
    }


    .SN-view {
        color: #fff;
        background-color: #00a9b3;
        border-color: #00a9b3;
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        border: 1px solid transparent;
        padding: 5px;
        font-size: 9px;
    }
}
