.testing-section-para{
    font-size: 18px;
    max-width: 1050px;
    margin: 0 auto;
    color: #333;
}

@media screen and (max-width: 768px) {
    .testing-section-para{
        font-size: 16px;
    }
}