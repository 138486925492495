/* Header Banner-Content */
.BlogHeader-sections {
  background-image: url('../../images/OurWork/BackgroundPage.webp');
  background-position: center;
  padding: 50px 50px !important;
  min-height: calc(100vh - 350px) !important;
  display: flex !important;
  align-items: center !important;
  background-position: center !important;
  margin-top: 60px !important;
  height: 70vh;
}

.Blog-banner-content .head-one {
  margin-bottom: -4px;
}

.Blog-banner-content h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;
  color: #131022 !important;
}

.Blog-banner-content h1 span {
  position: relative;
  z-index: 1;
  width: max-content;
  font-family: Manrope, sans-serif;
}

.Blog-banner-content p.sub-p {
  font-style: normal;
  line-height: 30px;
  padding-right: 10px;
  margin-bottom: 30px;
  max-width: 615px;
  margin-top: 17px;
  text-align: justify;
  word-spacing: -0.1em;
  hyphens: auto;
  color: gray !important;
  font-weight: 100;
  font-size: 18px;
  font-family: "Manrope", sans-serif;
}

.Blog-banner-button {
  display: inline-flex;
  margin-top: 30px;
  margin-left: -18px;
}

.blog-theme-btn {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: .04em;
  color: #ffffff;
  margin-left: 18px;
  box-shadow: 0 30px 40px -18px rgba(36, 144, 239, .3);
  border: 1px solid var(--theme-color);
  border-radius: 6px;
  padding: 18px;
  transition: ease-in-out .5s;
  text-decoration: none;
  background-color: #6366f1 !important;
  animation: pulse 5s infinite !important;
  border: 2px solid #fff !important;
  color: #fff !important;
}

.blog-home {
  background-color: #f8f8f8;
  text-align: center;
}

.blog-img {
  width: 100%;
  object-fit: cover;
  height: 180px;
}

.blog-item-content {
  padding: 22px 28px;
  text-align: left;
  height: 25vh;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 6%);
  display: grid;
}

.blog-header {
  font-size: 22px;
  line-height: 1.36;
  color: #0b2e58;
  height: 59.825px;
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 3%;
}

.blog-sesction-header {
  margin-bottom: 50px;
  font-size: 3rem;
  line-height: 1.26;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #0b2e58;
}

.blog-content-para-wrapper {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-family: "Manrope", sans-serif;
}

.Blog-banner-col {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media(max-width:1200px) {
  .BlogHeader-sections {
    height: 100% !important;
  }
}

@media(max-width:992px) {
  .image-lottie {
    margin-top: 20px !important;
  }

  .Blog-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .blog-item-content {
  }
  .blog-content-para-wrapper p{
    font-size: 0.7rem;
    
  }
  .blog-header{
    font-size: 1rem;
    margin: 0;
  }
}

@media(max-width:480px) {
  .BlogHeader-sections {
    height: 100% !important;
    padding: 67px 7px !important;
  }

  .Blog-banner-content h1 {
    font-size: 27px !important;
    line-height: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .Blog-banner-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blog-theme-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Blog-banner-content p.sub-p {
    max-width: 100% !important;
    font-size: 17px;
    line-height: 1.5;
    font-weight: 400;
  }

  .blog-sesction-header {
    font-size: 2.5rem;
  }
}
@media(max-width:1024px) {
  .blog-content-para-wrapper p{
    margin-top: 10px;
    
  }
}