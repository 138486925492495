/* Main Container */
.bg-video-wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* For 16:9 aspect ratio (100 / aspect ratio * 100%) */
  overflow: hidden;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
}

/* Overlay Text */
.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center text both vertically and horizontally */
  color: #ffffff; /* White text for better contrast */
  z-index: 2;
  padding: 1rem;
  max-width: 90%; /* Ensure the text stays within bounds */
}

/* Subheading Styling */
.subheading {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 1rem;
}

/* Main Heading Styling */
.main-heading {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.main-heading span {
  color: #00d1ff; /* Highlight color */
}

/* Responsive Design */
@media (max-width: 767px) {
  .main-heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 0.9rem;
  }

  .text-overlay{
    position: absolute;
    top: 86%;
    left: 10%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    z-index: 2;
    padding: 1rem;
    max-width: 100%;
  }

  .video-bg{
    top: 65px
  }
}

@media (min-width: 768px) {
  .text-overlay{
    top: 60%;
    left: 35%;
  }
}

@media (max-width: 480px) {
  .main-heading {
    font-size: 1.5rem;
  }

  .subheading {
    font-size: 0.8rem;
    line-height: 1.4;
  }
 .Typewriter__wrapper{
  font-size: 12px;
  margin-top: 30px;
 }
 .subheading{
  display: none !important;
 }
}
