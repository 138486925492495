.swipper-slider{
    width: 270.75px;
    margin-right:8px;
    height: 100%;
    position: relative;
    transition-property: transform;
}
.inside-card{
    border: none;
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.5rem;
    box-shadow: none !important;
    border: none !important;
}
.client-img{
    max-width: 100%;
    height: auto;
    vertical-align:middle ;
}
.some-client{
    color: #131022;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 1.4;
    letter-spacing: -0.4px;
    font-size: 3rem;
    font-family: Manrope, sans-serif;
    margin-top:50px;
    

}

.user-number{
    color: #21aae4;
    font-family: Manrope, sans-serif;
    font-size: 3.3rem;
    font-weight: 700;
}

.elementor-counter-number{
    color: #21aae4;
    font-family: Manrope, sans-serif;
    font-size: 3.3rem;
    font-weight: 700;
}
.number-text{
    display: flex !important;
    justify-content: center;
    font-family: Manrope, sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding:18px;
}
.OurImpact{
    color: #131022;
    font-weight: 400;
    margin-bottom: 50px;
    line-height: 1.4;
    letter-spacing: -0.4px;
    font-size: 3rem;
    font-family: Manrope, sans-serif;
    margin-top: 40px;
}

@media(max-width:480px){
    .some-client{
        color: #131022;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: -0.4px;
        font-size: 1.8rem;
        font-family: Manrope, sans-serif;
        margin-top: 50px;
    }
}