
 /* Header Banner-Content */  
.ConsultingHeader-sections {
    background-image:url('../../../images/OurWork/BackgroundPage.webp');
    background-position: center;
    padding: 73px 50px !important;
    min-height: calc(100vh - 350px) !important;
    display: flex !important;
    align-items: center !important;
    background-position: center !important;
    margin-top: 60px !important;
    height: 90vh !important;
  }
  .OutSourcing-banner-content {
    margin-top: 41px;
  }
  .OutSourcing-banner-content h1{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color:#131022 !important;
  }
  .OutSourcing-banner-content h1 span{
    position: relative;
    z-index: 1;
    width: max-content;
    font-family: "Manrope", sans-serif;
  }
  .OutSourcing-banner-content p.sub-p{
    font-style: normal;
    line-height: 30px;
    padding-right: 10px;
    margin-bottom: 30px;
    max-width: 615px;
    margin-top: 17px;
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
    color: gray !important;
    font-weight: 100;
    font-size: 18px;
    font-family: "Manrope",sans-serif;
  }
  
  .banner-button{
    display: inline-flex;
    margin-top: 30px;
    margin-left: -18px;
  }
  .theme-btn{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    background: #0d6efd;
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
  }
  
  .theme-btn .them-btn-alt {
    border: 1px solid #ffff;
    box-shadow: 0 30px 40px -18px rgba(255,255,255,.3);
    border-radius: 6px;
    background: transparent;
  }
  .banner-button a{
    margin-left: 20px;
  }
 
 
  /* New css */

  .Outsourcing-header{
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    margin-bottom: 50px;
    text-align: center;
  }
  .project-col{
    align-items: stretch;
    background: #ebeced;
    border-left: 1px solid #d8d9dc;
    border-top: 1px solid #d8d9dc;
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: -1px 0 0 -1px;
    padding: 40px !important;
    text-decoration: none;
}

.project-col .second-img{
    display: none;
    height: 64px;
    width: 64px;
}

.project-col .first-img{
    height: 64px;
    width: 64px;
}

.project-col h3{
    font-size: 20px;
    font-weight: 400;
    margin: 20px 0;
}

.project-col p{
    font-weight: 400;
    line-height: 24px;
    margin: 16px 0;
}

.project-col:hover{
background: #73C8A9;  
background: -webkit-linear-gradient(to right, #373B44, #73C8A9);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #373B44, #73C8A9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
color: white;
}

.project-col:hover .first-img{
    display: none !important;
}

.project-col:hover .second-img{
    display: block !important;
}

.title-maximize h2 {
  margin-bottom: 50px;
}


.out-image{
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
   }

   .out-image .image{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 80%;
       }
.OutSourcing-banner-col{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



  /* OutSourcing SecondPart Css     */
    .Figure-header{
      color: #004485;
      font-family: "Manrope", sans-serif;
      font-size: 37px;
      font-weight: 400;
      line-height: 1.28;
      margin: 50px 0 50px;
      text-align: center;
    }
    .Figure-row{
      margin: 30px 0px 50px 0px;
    }
    .item-amout-title{
      font-size: 38px;
      line-height: 1em;
      color: #005eb8;
      font-weight: 600;
      text-align: left;
  }
     .b-overview__para{
        font-size: 18px;
        line-height: 1.6;
        color: #333;
        margin-top: 25px;
        text-align: left;
      }
      .overview-item{
        border-left: 2px solid #e1e1e1;
        padding: 0 1em;
        text-align: center;
      }

      .Support-Header{
        color: #004485;
        font-family: "Manrope", sans-serif;
        font-size: 36px;
        font-weight: 400;
        line-height: 1.28;
        margin: 128px 0 50px;
        text-align: center;
      }
      .logo-item{
        height: auto;
        text-align: center;
        width: 120px;
        max-width: 100%;
      }
      .ss-item{
        border: 1px solid #dadfe3;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 25px 20px;
      }
      .item-layer{
        text-align: center;
      }
      .item-para{
        font-size: 20px;
        font-weight: 700;
        padding:20px 0px 10px 0px;
      }

      @media (max-width: 1500px){
        .ConsultingHeader-sections{
            height: 100% !important;
        }
      }
    
   @media (max-width:992px) {
      .out-image .image{
        width: 60% !important;
      }
      .ConsultingHeader-sections{
        height: 100% !important;
      }
   }

   @media (max-width:680px) {
      .out-image .image{
      width: 80% !important;
    }
 }

   @media (max-width:480px) {
      .out-image .image{
      width: 100% !important;
    }
    .ConsultingHeader-sections{
      height: 100% !important;
    }

    .outsourcing-row{
      display: grid;
      grid-template-columns: auto !important;
    }

    .outsourcing-col1{
      text-align: center !important;
      margin-bottom: 30px !important;
    }

    .card-outsourcing h3{
      text-align: center;
    }

    .title-maximize h2{
        text-align: center !important;
    }
    .OutSourcing-banner-content h1{
      font-size: 27px !important;
      line-height: 36px !important;
      text-align: center;
     }
     .OutSourcing-banner-content p.sub-p{
      max-width: 100% !important;
      font-size: 15px;
      line-height: 1.5;
      font-weight: 400;
  }
  .ConsultingHeader-sections{
    padding: 25px 7px !important;
  }
  .Outsourcing-header {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 50px;
    text-align: center;
  }
  .Figure-header {
    color: #004485;
    font-family: "Manrope", sans-serif;
    font-size: 29px;
    font-weight: 500;
    line-height: 1.20;
    margin: 0px 0 50px;
    text-align: center;
  }
  .overview-item {
    padding: 0 3em;
  }
  .Support-Header{
    font-size: 33px;
    font-weight: 400;
    line-height: 1.24;
    margin: 10px 0 50px;
    text-align: center;
  }
 }
