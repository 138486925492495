.first-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.whowe-header{
     line-height: 1.3 !important;
     color: #131022 !important;
     margin-bottom: 1rem !important;
     font-size: 2.5rem !important;
     font-weight: 600;
}
.whowe-para{
    font-size: 17px;
    text-align: justify;
    color: #585c7b;
    font-family: "Manrope", sans-serif;
}
.whowe-section {
    padding: 30px;
}
.know-more{
    color: #ffff;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(99,102,241,.9) !important;
    padding: 0.785rem 2rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    background-color: #6366f1;
    border-color: #6366f1;
    font-weight: 600;
    line-height: 1.6;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
}
.whowe-section img{
    border-radius: 0.5rem;
    box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(19, 16, 34, .06), 0 0.125rem 0.4rem -0.0625rem rgba(19, 16, 34, .03) !important;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
@media(max-width:480px){
    .whowe-header{
        font-size: 2.1rem !important;
        margin-top: -39px !important;
    }
    .whowe-para{
        word-spacing: -0.1em;
        hyphens: auto;
        font-size: 17px;
    }
}
