/* Header Banner-Content */  
.Testing-Header-sections{
    background-image:url('../../../images/OurWork/BackgroundPage.webp');
    background-position: center;
    padding: 73px 50px !important;
    min-height: calc(100vh - 350px) !important;
    display: flex !important;
    align-items: center !important;
    background-position: center !important;
    margin-top: 60px !important;
    height: 90vh !important;
  }
 
  .Testing-banner-content h1{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color:#131022 !important;
  }
  .Testing-banner-content h1 span{
    position: relative;
    z-index: 1;
    width: max-content;
    font-family:"Manrope, sans-serif";
  }
  .Testing-banner-col{
    display: flex;
    justify-content:flex-start;
    align-items: center;
  }
  .Testing-banner-content p.sub-p{
    font-style: normal;
    line-height: 30px;
    padding-right: 10px;
    margin-bottom: 30px;
    max-width: 615px;
    margin-top: 17px;
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
    color: gray !important;
    font-weight: 100;
    font-size: 18px;
    font-family: "Manrope",sans-serif;
  }
  
  .Testing-banner-button{
    display: inline-flex;
    margin-top: 30px;
    margin-left: -18px;
  }
  .Testing-theme-btn{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    margin-left: 18px;
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
    background-color: #6366f1 !important;
    animation: pulse 5s infinite !important;
    border: 2px solid #fff !important;
    color: #fff !important;
  }
  
  /* new css */
  .web-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .web-container .Testing-comapny-header{
    font-family:"Manrope, sans-serif";
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 50px;
    color: gray;
    margin: 0 0 30px 0;
  }
  
  .web-container .Testing-comapny-para{
    font-family:"Manrope, sans-serif";
    font-style: normal;
    width: 80%;
    font-weight: 400;
    font-size: 19px;
    line-height: 30px;
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
  }
  
  .testing-image{
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
     }
  
     .testing-image .image{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 80%;
         }

         @media (max-width: 1500px){
          .Testing-Header-sections{
              height: 100% !important;
          }
        }     
  
     @media (max-width:992px) {
        .testing-image .image{
          width: 70% !important;
        }
        .Testing-Header-sections{
          height: 100% !important;
        }
  
        .lifecycle-image img{
          width: 100% !important;
        }
       
     }
  
     @media (max-width:768px) {
      .lifecycle-image img{
        width: 80% !important;
      }
   }
  
     @media (max-width:680px) {
        .testing-image .image{
        width: 80% !important;
      }
   }
  
     @media (max-width:480px) {
        .testing-image .image{
        width: 100% !important;
      }
      .Testing-Header-sections{
        height: 100% !important;
        padding: 67px 7px !important;
      }
      .Testing-banner-content h1{
        font-size: 27px !important;
        line-height: 36px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }
      .Testing-banner-content p.sub-p{
        line-height: 25px;
        font-size: 15px;
        font-weight: 400;
      }
      .web-container .Testing-comapny-para{
          font-size: 17px;
          line-height: 29px;
      }
      .Testing-banner-button{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .Testing-theme-btn{
        display: flex;
        align-items: center;
        justify-content: center;
      }
   }
  
   @media (max-width:380px) {
    .service-widget{
    height: 52vh !important;
  }
  }
  