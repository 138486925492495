.marketing-section {
    background-color: rgb(21,49,94);
  }
  
  .marketing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 50px 0px;
    left: 0;
    border: none;
  }
  
  .marketing-container h3 {
    color: white;
    font-weight: 600;
  }
  
  .marketing-row {
    column-gap: 40px;
  }
  
  .marketing-col .card {
    text-align: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 20px;
    border: none;
    background: none;
    box-shadow: none;
  }
  
  .marketing-col .card-img {
    text-align: center;
    width: 130px;
    height: 130px;
    border-radius: 100px;
  }
  
  .headingName{
    padding-bottom: 30px;
  }
  .marketing-col .card-title {
    color: white;
  }
  
  @media (max-width: 992px) {
    .marketing-row {
      column-gap: 0px !important;
    }
  }
  