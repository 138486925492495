.Career-Banner-section{
    background-image: url("../../images/Careers/CareerNew2.png");
    background-repeat: no-repeat;
    background-size: cover;
   
    background-position: center;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 60px;
}

.banner-content-Careers h1{
    font-size: 40px;
    line-height: 58px;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 30px;
}
.banner-content-Careers span{
    position: relative;
    z-index: 1;
    width: max-content;
}
.banner-content-Careers p.sub-p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #EBEBEB;
    padding-right: 10px;
}
.banner-content-col{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* media query justify content = center */
}

.Careers-banner-button{
    display: inline-flex;
    margin-top: 30px;
    /* margin-left: -18px; */
    height: 55px;
}
.Careers-theme-btn{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    /* margin-left: 18px; */
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
    background-color: #6366f1 !important;
    animation: pulse 5s infinite !important;
    color: #fff !important;
}

@media(min-width:767px){
    .Career-Banner-section{
        min-height: 450px;
    }
    .Careers-theme-btn{
        margin-left: 18px !important;
    }
   
}
/* @media(max-width:768px){

    .banner-content-Careers{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
    }
    .Career-Banner-section{
        height: 200px !important;
    }
    .banner-content-Careers h1{
        font-size: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size:0.8rem;
    }
    .lifeatSenwell-card{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .banner-content-Careers p.sub-p{
        max-width: 100% !important;
        font-size:0.2rem;
       
    }
    .banner-content-Careers p.sub-p {
        max-width: 100% ;
       
        font-size:0.2rem;
         padding-right: 0px;
         text-align: center;
    }
    .Careers-banner-button{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Careers-theme-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        font-size: 0.3rem;
    }
} */

@media (max-width: 767px) {
    .Career-Banner-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: auto;
      padding: 20px;
      height: 200px !important;
    }
  
    .banner-content-Careers {
    margin-top: 20px;  
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    .banner-content-Careers h1 {
      font-size: 14px !important; /* Adjusted for better readability */
      line-height: 1.2;
      margin-bottom: 10px;
    }
  
    .banner-content-Careers p.sub-p {
      font-size: 9px !important; /* Increased for better legibility */
      line-height: 1.4;
      margin: 0 0 15px 0; /* Adjusted spacing */
      padding: 0;
      text-align: center;
      max-width: 90%; /* Ensures the text stays within bounds */
    }
  
    .Careers-banner-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  
    .Careers-theme-btn {
      font-size: 12px !important; /* Adjusted for responsiveness */
      padding: 10px 20px !important;
      text-align: center;
      width: auto; /* Fits the button content */
      border-radius: 6px;
      margin-left: 0px;
    }
  
    .lifeatSenwell-card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .banner-content-col{
        display: flex;
        justify-content: center;
        align-items: center;
        /* media query justify content = center */
    }
  }
  