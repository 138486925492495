/* Wrapper: Centers the component vertically and horizontally */
.custom-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    margin-top: 4%;
  }
  
  /* Container: Holds both the image and the Calendly widget */
  .custom-container {
    display: flex;
    flex-direction: row;
    width: 80%; /* Container width */
    max-width: 1200px; /* Maximum width */
    height: 80%; /* Container height */
    background-color: #ffffff; /* White background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
    overflow: hidden;
  }
  
  /* Image Section */
  .custom-image-section {
    flex: 1; /* Take 50% of the width */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9; /* Light gray background */
  }
  
  .custom-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain; /* Maintain aspect ratio */
  }
  
  /* Calendly Section */
  .custom-calendly-section {
    flex: 1; /* Take 50% of the width */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .custom-wrapper{
        margin-top: 22% !important;
        height: 70%;
    }
    .custom-container {
      flex-direction: column; /* Stack vertically on smaller screens */
      width: 90%;
      height: auto; /* Allow height to adjust */
     
    }
  
    .custom-image-section,
    .custom-calendly-section {
      flex: none;
      width: 100%;
      height: 50vh; /* Adjust height for smaller screens */
    }
  
    .custom-image {
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media (min-width: 768px) {
    .custom-wrapper{
        margin-top: 12% !important;
    }
   
  }
  @media (min-width: 1024px) {
    .custom-wrapper{
        margin-top: 3% !important;
    }
   
  }
  