
.servicesContainer1{
    padding-top: 0%;
    /* height: 500px; */
   
}

.containerWrapper1{
    position: relative;
    margin: auto;
    /* width: 150px; */
   height: 600px;
    overflow: hidden;
    cursor: pointer;
    border:1px solid #ccc;
    /* border-radius: 4px; */
    box-shadow: 1px 1px 10px 2px rgb(224, 224, 224), -1px 10px 10px 2px rgb(199, 216, 233);
    /* background-color: linear-gradient(90deg, hsla(200, 73%, 52%, 1) 44%, hsla(220, 60%, 37%, 1) 100%); */
    /* background-color: #fff !important; */
    color: black;
    background-image: url("Lotto.png");
    background-image: cover;
    /* background-position: center; */
    background-repeat: no-repeat;
    /* border-radius: 30px; */
  }
  
  .containerWrapper1 .overlay1{
    /* background: white; */
    color: black;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: .3s ease;
    /* width: 400px; */
    transition: .3s ease;
  }
  
  .containerWrapper1:hover .overlay1{
    opacity: 1;
    top:6rem;
    /* width: 100%; */
    height: 600px;
    /* background-color: black !important; */
    background-image: url("slide2.png"); 
    /* background-image: cover !important; */
     background-position: center;
    background-repeat: no-repeat;
    /* background-color: black; */
    color: black;
  }
  
  .overlay1 p{
    color:white;
    position: absolute;
    margin: auto;
    /* margin:10px 1px; */
   
    left: 20px;
    top: 6rem;
    right: 10px;
    bottom: 0;
    
    /* line-height: 25px; */
    transition: transformY(337px);
  
  }
  
  .containerWrapper1:hover .overlay1 span{
    opacity: 1;
    -webkit-transition: 1.3s ease;
    transition: 1.3s ease;
  }
  .gridContainer1{
    margin-bottom: 0px !important;
  }
  .containerWrapper2{
    position: relative;
    margin: auto;
    /* width: 150px; */
   height: 600px;
    overflow: hidden;
    cursor: pointer;
    border:1px solid #ccc;
    /* border-radius: 4px; */
    box-shadow: 1px 1px 10px 2px rgb(224, 224, 224), -1px 10px 10px 2px rgb(199, 216, 233);
    /* background-color: linear-gradient(90deg, hsla(200, 73%, 52%, 1) 44%, hsla(220, 60%, 37%, 1) 100%); */
    /* background-color: #fff !important; */
    color: black;
    background-image: url("Lotto.png");
    background-image: cover;
    /* background-position: center; */
    background-repeat: no-repeat;
    /* border-radius: 30px; */
  }
  
  .containerWrapper2 .overlay2{
    /* background: white; */
    color: black;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: .3s ease;
    /* width: 400px; */
    transition: .3s ease;
  }
  
  .containerWrapper2:hover .overlay2{
    opacity: 1;
    top:6rem;
    /* width: 100%; */
    height: 600px;
    /* background-color: black !important; */
    background-image: url("slide2.png"); 
    /* background-image: cover !important; */
     background-position: center;
    background-repeat: no-repeat;
    /* background-color: black; */
    color: black;
  }
  
  .overlay2 p{
    color:white;
    position: absolute;
    margin: auto;
    /* margin:10px 1px; */
   
    left: 20px;
    top: 6rem;
    right: 10px;
    bottom: 0;
    
    /* line-height: 25px; */
    transition: transformY(337px);
  
  }
  
  .containerWrapper2:hover .overlay2 span{
    opacity: 1;
    -webkit-transition: 1.3s ease;
    transition: 1.3s ease;
  }
  .gridContainer1{
    margin-bottom: 0px !important;
  }
