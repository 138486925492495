.section-heading{
    margin-bottom: 18px;
    font-size: 30px;
    font-weight: 600;
    line-height: 44px;
    color: #343434;
}


.section-padding{
  padding:70px 0pxs;
}
.capabilities-section{
    text-align: center;
}
.top-row{
    margin-bottom: 30px;
}
.section-heading{
    color: #343434;
    letter-spacing:0px;
    margin-bottom: 18px;
    font-size: 30px;
    font-weight: 600;
    line-height: 44px;
}
.digital-wrapper{
    background: #fff;
    box-shadow: 0 4px 10px #0000001A;
    height: 100%;
    text-align: left;
}
.digital-content{
    padding: 20px;
}
.digital-img{
    margin: auto;
    text-align: center;
    border-bottom: 2px solid #0000001A !important;
    padding: 20px;
}
.digital-content .section-sub-heading-cloud{
    font-size: 22px;
    color: #484848;
}
.digital-content .section-sub-heading-cloud a{
    color: #484848;
}
ul{
    margin-top: 0;
    margin-bottom: 1rem;
}
.core-values-wrapper{
    height: 100%;
    border: 0;
    padding: 40px 20px 20px;
    background: #fff;
}
.core-values-wrapper .core-icon{
    width: 60px;
    height: auto;
    margin-bottom: 20px;
}
.section-sub-heading-public{
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
}
.section-heading{
    color: #343434;
    margin-bottom: 18px;
    font-size: 30px;
    font-weight: 600;
    line-height: 44px;
}
.tools-section .top-row{
    margin-bottom: 30px;
}
.tools-title{
    font-size: 16px;
    line-height: 30px;
    color:#828282;
    padding: 0.5rem 1rem;
    transition: color .3s linear;
    cursor: pointer;
    font-weight: 500;
}

.tools-content-list{
    padding:50px 0 30px;
}
.tools-content.active{
    display: block;
}
.tools-icon-list{
    position: relative;
}
.tools-icon-list ul{
    list-style: none;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    column-gap: 10px;
    padding-left: 0;
}
.tools-icon-list ul li{
    flex: 1 0 auto;
    width: 140px;
    max-width: 140px;
    text-align: center;
    margin-bottom: 25px;
    border-right: 2px solid #d8d8d8;
    padding-right: 3px;
}
.tools-icon-list li img, .tools-icon-list li span{
    display: block;
    margin: auto;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-top: 5px;
    color:#484848;
}
.tools-icon-list li img{
    max-width: 60px;
}

@media screen and (max-width: 768px) {
    .section-heading{
        text-align: center;
        font-size: 27px;
    }
}