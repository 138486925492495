.our-work-container {
    scroll-margin-bottom: 160px;
}
    .our-work-content {
      max-width: 1200px;
      margin: 55px auto;
      position: relative;
    }
  
    .top-header {
      color: #3b3663;
      line-height: 1.4;
      letter-spacing: -0.4px;
      font-size: 3rem;
      font-weight: 500;
      font-family: Manrope, sans-serif;
      margin-top: 50px;
      margin-bottom: 50px;
      margin-bottom:0;
    }
    .Work-Para{
      font-size: 17px;
      padding-bottom: 1rem !important;
      text-align: center;
      color: #585c7b;
      margin-bottom: 1.5rem !important;
      font-family: "Manrope", sans-serif;
    }

  
    .tab-container {
      justify-content: space-between;
      display: flex;
      padding-top: 32px;
      align-items: center;
    }
      .left-content {
        display: flex;
      }
  
      .tab-item {
        margin-right: 20px;
        padding: 5px 10px;
        box-sizing: border-box;
        border-radius: 4px;
        color: #07172d;
        border: 1px solid rgba(7, 23, 45, 0.38);
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
      .tab-item:hover {
          cursor: pointer;
        }
  
      .active {
        font-weight: 700;
        color: #2c74d6;
        border: 2px solid #2c74d6;
      }
    
  
    .work-view {
      display: flex;
      padding-top: 30px;
    }

      .work-item {
        width: 33%;
      }
        .work-item:hover {
          cursor: pointer;
        }
  
      .project-title {
        font-style: normal;
        font-size: 20px;
        line-height: 30px;
        padding-top: 16px;
      }
  
      .project-sub-text {
        font-size: 14px;
        padding-top: 8px;
        max-width: 284px;
      }
  
    .right-content {
      display: flex;
    }
  
    .left-button {
      width: 56px;
      height: 56px;
      border: 1px solid rgba(7, 23, 45, 0.38);
      box-sizing: border-box;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
  
    .display-image {
      margin: 8px 0;
      border-radius: 4px;
      border: 1px solid #efefef;
    }
    .display-image img {
        border-radius: 4px;
      }
  
    .right-button {
      width: 56px;
      height: 56px;
      border: 1px solid #07172d;
      box-sizing: border-box;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
  .arrow-left {
    display: block;
    width: 15px;
    height: 15px;
    border-top: 2px solid rgba(7, 23, 45, 0.38);
    border-left: 2px solid rgba(7, 23, 45, 0.38);
    position: relative;
    left: 5px;
  }
    .arrow-left:hover {
      cursor: pointer;
    }
  
  
  .arrow-right {
    display: block;
    position: relative;
    right: 2px;
    width: 15px;
    height: 15px;
    border-top: 2px solid #07172d;
    border-left: 2px solid #07172d;
  }
  .arrow-right:hover {
      cursor: pointer;
    }
  
    .arrow-right{
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    
  .arrow-left {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .our-work-container {
      max-width: 80%;
      margin: auto;
    }

    .sub-text{
      text-align: justify;
      word-spacing: -0.1em;
      hyphens: auto;
      font-size: 16px;
    }
    
  
    .our-work-container .tab-container,
    .our-work-container .work-view {
        display: block;
    }
  
      .top-header {
        font-size: 2.8rem !important;
        margin-top: 10px;
      }
  
    .our-work-container .tab-container .work-item,
    .our-work-container .work-view .work-item {
        width: auto;
        margin-bottom: 50px;
    }
  
    .our-work-container .tab-container .left-content,
    .our-work-container .work-view .left-content {
        flex-wrap: wrap;
    }
  
    .our-work-container .tab-container .left-content .tab-item,
    .our-work-container .work-view .left-content .tab-item {
        width: 40%;
        margin: 10px;
    }
    }
  
  @media only screen and (max-width: 575px) {
    .our-work-container {
      margin: auto;
      padding-bottom: 100px;
    }

      .our-work-container .our-work-content {
        margin: auto;
      }
    }
  