.mobile-card{
    display: flex;
    justify-content:flex-start;
    text-align: start;
    text-align: start;
    flex-direction: column; 
}

/* Header Banner-Content */  
.MobileHeader-sections{
    background-image:url('../../../images/OurWork/BackgroundPage.webp');
    background-position: center;
    padding: 73px 50px !important;
    min-height: calc(100vh - 350px) !important;
    display: flex !important;
    align-items: center !important;
    background-position: center !important;
    margin-top: 60px !important;
    height: 90vh;
}

  .Mobile-banner-content h1{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color:#131022 !important;
}
.Mobile-banner-content h1 span{
    position: relative;
    z-index: 1;
    width: max-content;
    font-family: "Manrope", sans-serif;
}
.Mobile-banner-content p.sub-p{
    font-style: normal;
    line-height: 30px;
    padding-right: 10px;
    margin-bottom: 30px;
    max-width: 615px;
    margin-top: 17px;
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
    color: gray !important;
    font-weight: 100;
    font-size: 18px;
    font-family: "Manrope",sans-serif;
}

.Mobile-banner-button{
    display: inline-flex;
    margin-top:30px;
    margin-left: -17px !important;
}
.MobApp-btn{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    margin-left: 18px;
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
    background-color: #6366f1 !important;
    animation: pulse 5s infinite !important;
    border: 2px solid #fff !important;
    color: #fff !important;
}

/* Banner Animation */
.Mobile-banner-col{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.web-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }

 .web-container h2{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: black;
    margin: 0 0 30px 0;
 }

 .web-container .Mobile-para{
       font-family:"Manrope, sans-serif";
        font-style: normal;
        width: 80%;
        font-weight: 400;
        font-size: 19px;
        line-height: 30px;
        text-align: justify;
        word-spacing: -0.1em;
        hyphens: auto;
 }
 .Tech-we-use-Header{
    font-weight: 600;
    font-size: 33px;
 }

.mobile-image{
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
     }
     .App-Platform{
        font-family: "Manrope", sans-serif; 
     }

     .section_part1{
        background-color: #f9f9ff;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
     }
     .senwell-process{
        padding: 50px 0px;
       font-weight: bold;
       font-family: "Manrope", sans-serif;
     }
     .Mob-App-Process{
        margin-top: 10px;
     }

     .mobile-image .image{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
         }

         @media (max-width: 1500px){
            .MobileHeader-sections{
                height: 100% !important;
            }
          }    
         
     @media (max-width:992px) {
        .mobile-image .image{
          width: 70% !important;
        }

        .MobileHeader-sections{
            height: 100%;
        }
     }

     @media (max-width:768px) {
        .service-widget{
            height: 35vh !important;
        }
     }
  
     @media (max-width:680px) {
        .mobile-image .image{
        width: 80% !important;
      }
   }
  
     @media (max-width:480px) {
        .mobile-image .image{
           width: 100% !important;
         }
      .service-widget{
        height: 40vh !important;
    }
    .MobileHeader-sections{
        height: 100% !important;
        padding: 67px 7px !important;
    }
    
    .Mobile-banner-content h1{
        font-size: 23px !important;
        line-height: 34px !important;
        display: flex;
        align-items: center;
        justify-content: center;
       flex-direction: column;
    }
    .web-container .Mobile-para{
        font-size: 17px;
        line-height: 29px;
    }
    
    .Mobile-banner-content p.sub-p{
        line-height: 25px;
        word-spacing: -0em;
        font-size: 15px;
        font-weight: 400;
    }
    .Mobile-banner-button{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MobApp-btn{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .senwell-process{
        font-size: 21px;
    } 
    .Mob-App-Process{
        text-align: center;
        font-size: 14px !important;
        line-height: 19px;
    }
    .Tech-we-use-Header{
        font-weight: 500;
        font-size: 27px;
    }
    .Tech-we-use-Header-Para{
        width: 90%;
    }
   }

   @media (max-width:380px) {
  .service-widget{
    height: 47vh !important;
  }

  .mobile-card{
    align-items: center;
  }
}

   