.mob-section-para{
    font-size: 18px;
    max-width: 765px;
    margin: 0 auto;
    color: #333;
}

@media screen and (max-width: 768px) {
    .mob-section-para{
        font-size: 16px;
    }

    .design-and-content-header {
        font-size: 23px !important;
        line-height: 34px !important;
    }
}