/* Header Banner-Content */  

.DesingHeader-sections{
    background-image:url('../../../images/OurWork/BackgroundPage.webp');
    background-position: center;
    padding: 73px 50px !important;
    min-height: calc(100vh - 350px) !important;
    display: flex !important;
    align-items: center !important;
    background-position: center !important;
    margin-top: 60px !important;
    height: 90vh;
}
  
  .Design-banner-content h1{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color:#131022 !important;
}
.Design-banner-content h1 span{
    position: relative;
    z-index: 1;
    width: max-content;
    font-family: "Manrope", sans-serif;
}
.Design-banner-col{
    display: flex;
    justify-content:flex-start;
    align-items: center;
}
.Design-banner-content p.sub-p{
  font-style: normal;
  line-height: 30px;
  padding-right: 10px;
  margin-bottom: 30px;
  max-width: 615px;
  margin-top: 17px;
  text-align: justify;
  word-spacing: -0.1em;
  hyphens: auto;
  color: gray !important;
  font-weight: 100;
  font-size: 18px;
  font-family: "Manrope",sans-serif;
}

.Disign-banner-button{
    display: inline-flex;
    margin-top: 30px;
    margin-left: -18px;
}
.Design-theme-btn{
  font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    margin-left: 18px;
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
    background-color: #6366f1 !important;
    animation: pulse 5s infinite !important;
    border: 2px solid #fff !important;
    color: #fff !important;
}

.theme-btntheme-btn .them-btn-alt {
    border: 1px solid #ffff;
    box-shadow: 0 30px 40px -18px rgba(255,255,255,.3);
    border-radius: 6px;
    background: transparent;
}




.design-images{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
     }

     .design-images .image{
        width: 100% !important;
         }
  
     @media (max-width:992px) {
        .design-images .image{
          width: 70% !important;
        }
        .DesingHeader-sections{
          height: 100%;
        }
     }
  
     @media (max-width:680px) {
        .design-images .image{
        width: 100% !important;
      }
   }
  
     @media (max-width:480px) {
        .design-images .image{
        width: 100% !important;
      }
      .DesingHeader-sections{
        height: 100% !important;
        padding: 67px 7px !important;
      }
      .Design-banner-content h1{
        font-size: 23px !important;
        line-height: 34px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .Ui-help{
        font-size: 29px !important;
      }
      .Ui-help-pare{
        line-height: 23px;
        font-size: 19px !important;
      }
      .Design-banner-content p.sub-p{
        line-height: 25px;
        font-size: 15px;
        font-weight: 400;
      } 

      .Disign-banner-button{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .Design-theme-btn{
        display: flex;
        align-items: center;
        justify-content: center;
      }
     
   }
