.blog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.blog-container-date {
    text-align: center;
    color: #8B8B8B;
    margin: 10px 0 25px 0px;
}

.blogMain-header {
    font-size: 40px;
    text-align: center;
    letter-spacing: 0px;
    color: #054776;
    opacity: 1;
    line-height: 4rem;
    font-weight: bold;
    margin: 10px 0;
}

.image-blog-heaeder {
    width: 100%;
}

.image-blog-heaeder img {
    object-fit: cover;
    border-radius: 26px;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

.content-blog {
    padding: "50px 0px"
}

.entry-title {
    font-size: 2.25rem;
    color: #404040;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    font-family: "Manrope", sans-serif;
    text-align: start;
    letter-spacing: 0;
    margin: 40px 0 20px 0px;
}

/* New Css */
.ReactAppDevelopment1 {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    overflow-wrap: break-word;
    font-size: 1.0625rem;
    color: #404040;
    font-family: "Manrope", sans-serif;
    text-align: justify;
    line-height: 1.5;
}

.link-blog {
    color: #22aadd;
    text-decoration: underline;
}

.ReactAppDevelopment2 {
    margin-bottom: 15px;
    font-size: 1.0625rem;
    font-family:"Manrope", sans-serif;
    text-align: justify;
    color: #404040;
}

.entry-sub-title {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    overflow-wrap: break-word;
    font-size: 1.7rem !important;
    color: #404040;
    text-align: start;
}

.entry-sub-title-2 {
    margin-top: 1.5em;
    overflow-wrap: break-word;
    font-size: 1.4rem;
    color: #404040;
    text-align: start;
}