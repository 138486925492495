 
.WebApp-sections{
  background-image:url("../../../images/OurWork/BackgroundPage.webp");
  background-position: center;
  padding: 73px 50px !important;
  min-height: calc(100vh - 350px) !important;
  display: flex !important;
  align-items: center !important;
  background-position: center !important;
  margin-top: 60px !important;
  height: 90vh;
}
  .WebApp-banner-content .head-one{
    margin-bottom: -4px;
  }
  .WebApp-banner-content h1{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color:#131022 !important;
}
.WebApp-banner-content h1 span{
    position: relative;
    z-index: 1;
    width: max-content;
    font-family: "Manrope", sans-serif;
}
.WebApp-banner-content p.sub-p{
    font-style: normal;
    line-height: 30px;
    padding-right: 10px;
    margin-bottom: 30px;
    max-width: 615px;
    margin-top: 17px;
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
    color: gray !important;
    font-weight: 100;
    font-size: 18px;
    font-family: "Manrope",sans-serif;   
}

.Web-banner-button{
    display: inline-flex;
    margin-top: 30px;
    margin-left: -16px !important;
}
.WebApp-btn{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    margin-left: 18px;
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
    background-color: #6366f1 !important;
    animation: pulse 5s infinite !important;
    border: 2px solid #fff !important;
    color: #fff !important;
}
/* For New Css-1 */

@media (min-width: 1366px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1200px!important;
    }
    }
    .col-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .service-wrapper{
        margin-top: 54px;
    }
    .service-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    h2.service-title{
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        color: black;
        margin: 0 0 30px 0;
    }
       .web-app p{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70vw;
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: var(--grey-color);
        margin: 20px 20px;
    }

      /* For New Css Card */

      .section_part{
        background-color: #f9f9ff;
        max-width:100%;
        height: 87vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .service-widget{
        background-color:#ffffff;
        box-shadow: 0 10px 30px rgba(162,182,212,.1);
        border-radius: 10px;
        padding:20px;
        transition: ease-in-out .5s;
        height:40vh;
      }
      .service-widget .sw-head{
        display: flex;
        align-items: center;
      }
      .service-widget .sw-head .sw-logo{
        box-shadow: 25.27px 31.5875px 88.4449px rgba(227,240,254,.7);
        border-radius: 10px;
        margin-right: 15px;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
      .service-widget .sw-head .sw-title{
      font-family: "Manrope", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: black;
      max-width: calc(100% - 65px);
      }
      .service-widget .sw-content p{
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: var(--grey-color);
        margin: 10px 0 10px 0;
      }
      
      .js-Angular-image{
        margin-left: -41px;
      }
      .js-React-image{
        margin-left: -55px;
      }

    
    /* For New css-2 ------ */
    .gray-background-block{
        background-color: #f8f8f8;
        padding: 4.8rem 0rem 2rem;
    }
    
    .gray-background-block .container{
        max-width: 119rem;
        padding: 0rem 2.5rem;
        margin: 0 auto;
    }
    
    .senwell-technology-detail h2{
        font-size: 2rem;
        margin-bottom: 1.5rem;
        line-height: 1.26;
        font-family: "Manrope", sans-serif;
        font-weight: 400;
        color: #0b2e58;
    }
    .senwell-technology-detail p{
        color: #333;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.8;
        margin-bottom: 3.5rem;
        max-width: 81%;
        font-family: "Manrope", sans-serif;
    }
    .senwell-technology-links{
        margin-bottom: 5rem;
    }
    .technology-listing{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0rem -2.6rem;
    }
    .technology-listing li{
        padding: 0rem 0.6rem;
        margin: 0;
    }
    .technology-listing ul li{
        font-size: 1.8rem;
    }
    
    .technology-listing li .blue-border-btn{
        position: relative;
        border: 1px solid #156ba3;
        display: inline-flex;
        color: #156ba3;
        font-size: 19px;
        font-weight: 400;
        padding:0px 12px;
        min-width: 48px;
        align-items: center;
        justify-content: center;
        list-style-type: none !important;
    }
    .technology-listing li .blue-border-btn:hover{
        background-color:#156ba3 ;
        color: #ffff;
    }
    
    .Senwell-technology-logos .technology-logos-listing {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0rem -3.5rem;
        box-sizing: border-box;
        
    }
    .Senwell-technology-logos .technology-logos-listing li {
        padding: 0rem 2rem;
        display: inline-flex;
        align-items: center;
        margin: 0rem 0rem 3rem;
        box-sizing: border-box;
    }


    /* For Backend */
    .gray-background-block1{
        background-color: #ffffff;
      }
      .gray-background-block1 .container{
        max-width: 119rem;
        padding: 0rem 2.5rem;
        margin: 0 auto;
    }
    .gray-background-block1 .container .senwell-technology-detail h2{
        font-size: 2rem;
        margin-bottom: 1.5rem;
        line-height: 1.26;
        font-family: "Manrope", sans-serif;
        font-weight: 400;
        color: #0b2e58;
        margin-top: 40px;
    }

    /* For DataBase */
    .gray-background-block2{
        background-color: #f8f8f8;
      }
      .gray-background-block2 .container{
        max-width: 119rem;
        padding: 0rem 2.5rem;
        margin: 0 auto;
    }
    .gray-background-block2 .container .senwell-technology-detail h2{
        font-size: 2rem;
        margin-bottom: 1.5rem;
        line-height: 1.26;
        font-family: "Manrope", sans-serif;
        font-weight: 400;
        color: #0b2e58;
        margin-top: 40px;
    }

    /* Technolgy we work */

    ul.tech-list{
       list-style: none;
       margin: 0;
       padding: 0;
       display: inline-flex;
       width: 100%;
       justify-content: center;
       flex-wrap: wrap;
}

ul.tech-list li {
    background: transparent;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    max-width: 130px;
    padding: 8px 10px;
    flex-wrap: wrap;
}

ul.tech-list li .tech-icon{
    height: 74px;
    width: 100%;
    position: relative;
}
ul.tech-list li .tech-icon img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
}

ul.tech-list li div.tech-title{
    font-size: 16px;
    margin: 10px 0 0 0;
    font-style: normal;
    font-weight: 500;
    color: #192734;
}

.technology-wrapper h3{
    font-size:inner;
    font-size: normal;
    font-weight:700 ;
    font-size: 40px;
    line-height:50px ;
    color:#192734;
    margin:0 0 30px 0;
    text-align: center;
}

/* Home banner Video css */
.WebApp-banner-col{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.player-wrapper {
    position: relative;
    padding-top: 40.25%; 
    height: 0;
    width: 100%;
  }     

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .development-image{
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    }
  
     .web-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
     }

     .web-container .Web-header{
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 50px;
        color: gray;
        margin: 0 0 30px 0;
     }
     .Web-header{
         font-family:"Manrope",sans-serif;
     }

     .web-container .WebDev-para{
        font-family:"Manrope, sans-serif";
        font-style: normal;
        width: 80%;
        font-weight: 400;
        font-size: 19px;
        line-height: 30px;
        text-align: justify;
        word-spacing: -0.1em;
        hyphens: auto;
     }
     
     @media (max-width:1500px) {
        .section_part{
            height: 100% !important;
            padding: 50px 0px;
        }
        .service-widget{
            height: 100% !important;
        }
        .WebApp-sections{
            height: 100%;
        }
     }
        
     @media (max-width:1200px) {
        .section_part{
            height: 100%;
            padding: 50px 0px;
        }
     }

     @media (max-width:992px) {
        .development-image .image{
          width: 60% !important;
        }
        .WebApp-sections{
            height: 100%;
        }
     }

     @media (max-width:768px) {
        .development-image .image{
          width: 70% !important;
        }
     }
  
     @media (max-width:680px) {
      .development-image .image{
        width: 80% !important;
      }
   }
  
     @media (max-width:480px) {
      .development-image .image{
        width: 100% !important;
      }
    .WebApp-sections{
        padding:67px 7px !important;
    }
    .WebApp-sections{
        height:100% !important;
    }
    .senwell-technology-detail{
        max-width: 100% !important;
    }
    .senwell-technology-detail h2{
        display:flex;
        align-items:center;
        justify-content:center;
    }
    .senwell-technology-detail p{
        text-align: center;
    }

    .WebApp-banner-content h1{
        font-size: 24px !important;
        line-height: 36px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .web-container h2{
        font-size: 23px !important;
        line-height: 34px !important;
    }
    .WebApp-banner-content p.sub-p{ 
        line-height: 25px;
        word-spacing: 0em;
        font-size: 15px;
        font-weight: 400;
    }
    .Web-banner-button{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    .WebApp-btn{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .senwell-technology-detail p{
        max-width: 100% !important;
        font-size: 17px;
        line-height: 1.5;
    }
   
    .web-container .WebDev-para{
        font-size: 17px;
        line-height: 29px;
    } 
   }

   @media (max-width:380px) {
  .WebApp-banner-content h1{
    font-size: 27px !important;
    line-height: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center
   }
 }
    
    
    
    