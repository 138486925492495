


  .top-header-testimonial{
    color: black;
    font-style: normal;
    letter-spacing: 0;
    margin-bottom: 50px;
    font-size: 4rem !important;
    line-height: 77px;
    font-weight: 100;
  }
  .home-testimonial h3{
    color: var(--orange);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase
    }
    .home-testimonial h2{
      color: white;
      font-size: 28px;
      font-weight: 700
    }
    
    .testimonial-inner{
      position: relative;
      margin-top: 70px;
      margin-bottom: 20px;
    }
    .testimonial-pos{
      position: relative;
      top: 24px
    }
    .testimonial-inner .tour-desc{
      padding: 38px;
      
    }
    .color-grey-3{
       font-family: Manrope, sans-serif;
       font-size: 14px;
       color: #6c83a2
      }
      
      .testimonial-inner img.tm-people{
        width: 60px !important ;
        height: 60px !important;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
        max-width: none
      }
      .link-name{
        font-family: Manrope, sans-serif;
        font-size: 14px;
        color: #6c83a2
      }
      .link-position{
        font-family: Manrope, sans-serif;
        font-size: 12px;
        color: #6c83a2
      }

      /* New Testimonial */
      .testimonials-row{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 50px;
       row-gap: 30px; 
      }

      .tour-item{
        border: 1px solid green;
        height: 317px;
      }

      
      .second-p{
        font-family: Manrope, sans-serif;
        font-size: 20px;
        font-style: normal;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: center;
        font-weight: 400;
        width: 70%;
      }

      .slider-img{
        width: 74px;
        height: 74px;
      }


      #carouselExampleInterval{
        /* box-shadow: 13px 13px 51px 0px rgba(9.999999999999991, 50.99999999999997, 80, 0.16); */
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
        background-color: #FFFFFF;
        border-radius: 20px 20px 20px 20px;
        height: 100%;
        margin-bottom: 30px;
        padding: 30px 0px;
        height: 55vh !important;
      }

      .slider-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .clientSay-Header{
        color: #3b3663;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 1.4;
        letter-spacing: -0.4px;
        font-size: 3rem;
        font-weight: 500;
        font-family: Manrope, sans-serif;
        margin-top: 50px;
        margin-bottom: 50px;
      }
     
     .client-para{
      font-weight: 400;
      text-align: justify;
      color: #696687;
      font-size: 18px;
      font-family: Manrope, sans-serif;
      font-size: normal;
      line-height: 25px;
     }
     .c-project{
       margin-top: -20px;
       font-size: 13px;
    }
     

     .bottom-card{
      width: 67%;
      margin-top: 29px;
     }
     .testimonial-title{
        vertical-align: middle;
        font-weight: 700;
        color: #3B3663;
        font-family: Manrope, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 0px;
        margin-bottom: 7px;
     }
     .testimonial-sub-title{
        color: #696687;
        font-size: 12px;
        vertical-align: middle;
        font-weight: 400;
        line-height: 24px;
        font-family: Manrope, sans-serif;
     }
      

     @media (max-width:992px) {
      #carouselExampleInterval{
        height: 100% !important;
      }
    }
      @media (max-width:480px) {
        .top-header-testimonial{
          font-size: 3rem;
          text-align: center;
        }
      }

   /* New Css */
      .testimonial-box{
        background: var(--white-color);
        border: 1px solid #F7F7F7;
        box-shadow: 0px 10px 30px rgba(162, 182, 212, 0.15);
        border-radius: 15px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 50px;
        height: 100%;
      }
      .slider-p{
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: center;
        font-weight: bold;
        font-size: 26px;
      }
      .description-para{
        font-size: 16px;
        line-height: 1.62;
        color: #333;
        font-family: Manrope, sans-serif;
        text-align: justify;
        margin-top: 15px;
      }
       h4 .c-name {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #474F62;
        margin: 20px 0 10px 0;
        width: 100%;
      }

      @media(max-width:480px){
        .clientSay-Header{
           line-height: 1.2;
           letter-spacing: -0.4px;
           font-size: 1.8rem;
          font-weight: 600;
      }
      .c-project{
        margin-top: -20px;
        font-size: 12px;
      }
      .description-para {
        font-size: 16px;
        line-height: 1.62;
        margin-top: 15px;
        text-align: justify;
        word-spacing: -0.1em;
        hyphens: auto;
      }
    }
      

    
      
     
/* Add this CSS to make the rows flex containers */
.slick-row {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
}

/* Custom styles for the navigation buttons */
/* .slick-prev,
.slick-next {
  background-color: #2490EF;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, transform 0.3s;
  background-color: gray;
  height: 50px;
  width: 50px;
  border-radius: 100px;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #1c74c2;
}

.slick-prev {
  margin-right: 20px;
}

.slick-next {
  margin-left: 20px;
} */
