.Rewardsfirst-h3{
    font-size: 40px !important;
    line-height: 40px !important;
    color: white;
    text-align: center;
}

.Recognitionsecond-h3{
    color: #e84625 !important;
    font-size: 40px !important;
    line-height: 40px !important;
    text-align: center;
}

.Rewardsfirst-h3{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.second-sub-div{
    text-align: center;
}

@media (max-width:992px) {
    .partners-img{
        width: 75% !important;
    }
}

@media (max-width:768px) {
    .partners-img{
        width: 60% !important;
    }
}