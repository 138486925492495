.help-card{
	display: flex;
    justify-content: center;
    align-items: center;
}
.Ui-help{
    font-family: "Manrope", sans-serif;
    font-weight: 600;
	margin-bottom: 30px;
	font-size: 36px;	
}
.Ui-help-pare{
	font-family: "Manrope", sans-serif;
	font-size: 20px;
	padding:5px 0px 20px 0px;
}

.help-card:hover{
	background: #c31432;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #240b36, #c31432);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #240b36, #c31432); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */	
}

.help-card:hover .help-para{
	color: white;
}

.help-card:hover .help-h5{
	color: white;
	margin-top: 20px;
}

.help-section{
	background: #D3CCE3;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #E9E4F0, #D3CCE3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}