
.StaffingHeader-sections{
    background-image:url('../../../images/OurWork//BackgroundPage.webp');
    background-position: center;
    padding: 73px 50px !important;
    min-height: calc(100vh - 350px) !important;
    display: flex !important;
    align-items: center !important;
    background-position: center !important;
    margin-top: 60px !important;
    height: 90vh;
}
.banner-content {
    margin-top: 41px;
  }
  .Staffing-banner-content h1{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color:#131022 !important;
}
.Staffing-banner-content h1 span{
    position: relative;
    z-index: 1;
    width: max-content;
    font-family: "Manrope", sans-serif;
}
.Staffing-banner-content p.sub-p{
    font-style: normal;
    line-height: 30px;
    padding-right: 10px;
    margin-bottom: 30px;
    max-width: 615px;
    margin-top: 17px;
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
    color: gray !important;
    font-weight: 100;
    font-size: 18px;
    font-family: "Manrope",sans-serif;
}
  .IT-banner-button{
     display: inline-flex;
     margin-top: 30px;
     margin-left: -2px;
  }
  .Staffing-banner-col{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
.Contact-theme-btn{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
    background-color: #6366f1 !important;
    animation: pulse 5s infinite !important;
    border: 2px solid #fff !important;
    color: #fff !important;
}

.staf-Agu {
    text-align: center;
    margin-bottom: 1.5rem;
    justify-content: center;
}

.staf-header {
    padding: 25px;
}

.staf-header h2 {
    color: #171940 !important;
    font-weight: 600 !important;
    font-size: 2rem !important;
    line-height: 42px;
    margin: 0 0 32px 0;
    letter-spacing: -.05em;
}

.staf-header p {
    color: #777;
    line-height: 26px;
    margin: 0 0 20px;
    text-align: center;
}

.Experties-h4 {
    color: #212529 !important;
    font-size: 1.3em;
    letter-spacing: -.7px;
    margin-top: 5px;
    font-weight: 600 !important;
}

.Experties-para {
    color: #777;
    line-height: 26px;
    margin: 0 0 20px;
    text-align: center;
    font-size: 14px;
}

.icon-feature {
    display: inline-block;
    font-size: 35px;
    height: 45px;
    line-height: 45px;
    padding: 0;
    width: 45px;
    margin-top: 0;
    margin-bottom: 10px;
    background: 0 0;
    color: rgba(0, 0, 0, .06);
    border-radius: 0;
    border-color: #e65100;
    color: #e65100;
    text-align: center;
}

.box-content {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
}


.hiring-card {
    background-color: white;
    padding: 25px;
    border-radius: 5px;
}

.icon-Modal {
    color: #e65100 !important;
    font-size: 2.3em !important;
    margin-bottom: 1rem !important
}

.hiring-card h4 {
    color: #212529 !important;
    font-weight: 800;
    font-size: 1.2em !important;
    line-height: 27px;
    margin: 0 0 14px 0;
    letter-spacing: -.05em;
}

.HiringModelSection {
    color: #fff;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 42px;
    margin: 0 0 32px 0;
}
.section-hiring{
    background-color:#02638e;
    padding:50px 0px ;
}
.Modal-Icon{
    font-size: 14px !important;
    padding-right: 10px !important;
}


/* 3 step Css */
.elementor-header {
    color: #313131;
    font-family: "Manrope", sans-serif;
    font-size: 35px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1px;
    word-spacing: 2px;
    text-align: center;
    padding: 50px 0px 15px 0px;
}

.elementor-para {
    text-align: center;
    font-size: 18px;
    font-family: "Manrope", sans-serif;
    margin-bottom: -20px;
    color: #777;
}

.Augmentation-header img {
    height: 100%;
    width: 100%;
}

.Augmentation-header h3 {
    display: flex;
    text-align: center;
}
.staff-col{
    display: flex;
    align-items: center;
    justify-content: center;
}

.staff-col .card {
    box-shadow: none;
    border: none;
    background: none;
}

.staff-col .card .share {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../images/Staff/ShareRequirment.webp);
    background-position: center;
    background-size: 85%;
}
.staff-col .card .onboard {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../images//Staff/Share.webp);
    background-position: center;
    background-size: 85%;
}
.staff-col .card .Project{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../images/Staff/Delivery.webp);
    background-position: center;
    background-size: 85%;
}

.heading-h3 {
    position: absolute;
    text-align: center;
    top: 115%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    font-weight: 500;
    color: black;
    background-color: white;
    width: 100%;
    padding: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.staff-col .card .slide {
    width: 300px;
    height: 200px;
    transition: 0.5s;
}

.staff-col .card .slide.slide1 {

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: .7s;
    transform: translateY(100px);

}

.staff-col .card:hover .slide.slide1 {

    transform: translateY(0px);

}

.staff-col .card .slide.slide2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    transition: .8s;
    transform: translateY(-100px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    
}

.staff-col .card:hover .slide.slide2 {

    transform: translateY(0);
    height: 100%;
}


.staff-col .card .slide.slide2 .content p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #414141;
    word-spacing: 2px;
}

.staff-col .card .slide.slide2 .content h3 {

    margin: 0 0 10px 0;

    padding: 0;

    font-size: 24px;

    text-align: center;

    color: #414141;

}

.staff-col .card:hover .heading-h3{
    display: none;
}


/* New */
.Offer-section{
    background: linear-gradient(0deg, #F9F9FF 0%, #FFFFFF 47.4%, #F9F9FF 100%);
    padding: 100px 0;
    position: relative;
    overflow: hidden;
    z-index: 10;
}
.service-title{
    text-align: center;
    font-size: 38px;
    line-height: 50px;
    margin: 0 0 20px 0;
    font-style: normal;
    font-weight: 700;
    color: #192734;
}

.mainRight-Content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-content{
    padding: 0px 30px;
    flex-direction: column;
}
.feature-list{
    padding: 50px 0 0 0
}

.left-image{
    text-align: center;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    min-width: 485px;
    width: 485px;
}
.left-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
}
.feature-title{
    font-style: normal;
    line-height: 36px;
    color: #192734;
    font-size: 30px;
}
.feature-text{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #4C5A67;
    margin: 0;
}
@media (max-width:992px){
    .mainRight-Content{
        text-align: center;
    }
    .StaffingHeader-sections{
        height: 100%;
    }
}
@media (max-width:480px) {
   
.StaffingHeader-sections{
    height: 100% !important;
    padding: 67px 7px !important;
}
.left-image{
    min-width: 100%;
}
.staf-header{
    padding: 0px !important;
    margin-top: 24px;
}
.row-requirments{
    padding:"20px 0px";
}
.Staffing-banner-content h1{
    font-size: 23px !important;
    line-height: 34px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Staffing-banner-content p.sub-p{
        line-height: 25px;
        word-spacing: -0.3em;
        font-size: 15px;
        font-weight: 400;
}
.elementor-header{
    font-size: 27px;
}
.staf-header h2{
    font-size: 1.3rem !important;
    font-weight: 700 !important;
}
h2.service-title{
    font-size: 34px;
}


.IT-banner-button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Contact-theme-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.staf-header p{
    font-size: 15px;
    color: #777;
    line-height: 23px;
    margin: 0 0 20px;
    text-align: justify;
    word-spacing: -2px;
    hyphens: auto;
}
.elementor-header{
    font-size: 23px;
    line-height: 29px;
    font-weight: 700;
}
.elementor-para{
    text-align: center !important;
    font-size: 17px !important;
}
h2.service-title{
    font-size: 27px;
    line-height: 32px;
}
.feature-title{
    font-style: normal;
    line-height: 33px;
    color: #192734;
    font-size: 24px;
}
.feature-text{
    font-size: 15px;
    line-height: 22px;
    text-align: justify;
    margin-top: 20px;
}

}
