.sn-title{
    color: #004485;
    font-family: Sumana,serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 1.13;
    margin: 50px 0 30px;
}
.sn-sub-title{
    color: #004485;
    font-family: Sumana,serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.28;
    margin: 53px 0 22px;
    position: relative;
    z-index: 1;
}
.sn-para-content{
    text-align: justify;
    font-size: 1rem !important;
    margin-top: 0;
}
.user-data{
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 1.3;
    color: #131022;
    font-size: 1.125rem!important;
}
.Collect-row{
    padding: 30px 0 30px 0;
}
.Collect-data{
    color: #004485;
    font-family: Sumana,serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.28;
    padding:39px 0 16px;
    position: relative;
    z-index: 1;
}
.privacy-contact-para{
    margin-bottom:80px;
}