.CustomeDev{
    margin-top: 73px;
}
.section-services{
    background-color: #f8f8f8;
    padding: 70px 0;
}

h2.section-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #192734;
    margin: 0 0 30px 0;
}

h3.section-subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #4c5a67;
    max-width: 935px;
    margin: 0 auto 60px;
    width: 100%;
}


.value-we-offer-col{
    background: #FFF;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    padding: 30px;
    transition: 0.5s;
}

.value-we-offer-col .vwo-icon{
    width: 60px;
    height: 60px;
    overflow: hidden;
}
.value-we-offer-col h3{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    color: #192734;
    margin: 30px 0 20px 0;
}
.value-we-offer-col p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #4C5A67;
    margin: 0 5px 0 0;
}


@media screen and (max-width: 768px) {
    .CustomeDev{
        margin-top: 60px ;
    }

    .main-banner{
        padding:100px 0;
    }

    .main-banner .Product-title{
        font-size: 27px;
        line-height: 42px;
        font-weight: 600;
        color: lightgray;
        margin-bottom: 25px;
    }

    .main-banner .content-para{
        line-height: 1.8;
        font-size: 16px;
        color: lightgray;
    }

    .section-para {
        font-size: 16px;
    }
    .work-itemProduct{
        height: 557.4px;
    }
    .design-and-content-header {
         font-size: 27px !important;
         line-height: 37px !important;
         font-weight: 600;
    }
    .design-and-content-para{
        font-size: 16px;
    }
    h2.section-title{
      font-weight: 600;
      font-size: 29px;
      line-height: 39px;
    }
}

