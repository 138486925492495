@media (max-width:992px) {
    .firstCulture-para{
        width: 100% !important;
    }
}

@media (max-width:580px) {
    .image-row{
        display: grid !important;
        grid-template-columns: auto auto;
    }
}

.container-fluid h4{
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    margin-bottom: 30px;
}

.container-fluid .heading-p{
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 50px;
    text-align: justify;
}
.firstCalture-head{
    padding-bottom: 1rem !important;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 1.3;
    color: #131022;
}

.sportsMain{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.firstCulture-para{
    text-align: justify;
    font-size: 1rem !important;
    width: 90%;
    color: #585c7b;
}

.lifeatSenwell-para{
    font-size: 1rem !important;
    padding-bottom: 1.5rem !important;
    color: #585c7b;
    text-align: justify;
    width: 88%;
}
.lifeatSenwell-card{
    width: 100%;
}
.lifeStyleSenwell{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    text-align: justify !important;
}

.image-card img{
    width: 100%;
    text-align: center;
}
.Adventure-heading{
   font-weight: 600;
}

@media (max-width:1200px) {
    .sportsMain{
        display: block;
    }

    .image-card img{
        width: 100%;
    }

    .container-fluid{
        width: 100% !important;
    }
}

@media (max-width:992px) {
    .firstCalture-head{
        margin-top: 25px !important;
    }  
}
@media(max-width:480px){
    .Adventure-heading{
        font-size: 30px !important;
        font-weight: 600 !important;
    }
    .firstCalture-head{
        text-align: center;
    }
    .firstCulture-para {
        text-align: justify;
       font-size: 14px !important;
        width: 90%;
       color: #585c7b;
    }
    .container-fluid .heading-p{
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 0px !important;
    }
    .lifeatSenwell-para{
        font-size: 14px !important;
         padding-bottom: 1.5rem !important;
         color: #585c7b;
         text-align: justify;
         width: 88%;
         word-spacing: -0.1em;
         hyphens: auto;
    }
}