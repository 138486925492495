
    .App1 {
      width: 100%;
      margin: 0 auto;
      margin-top: 35px;
      margin-bottom: 35px;
    }
    
    .card-top{
        display: flex;
        justify-content: space-between;
    }
    
    .card-top > img {
      object-fit: cover;
      background: black;
      width: 90%;
      height: 100%;
      margin: auto;
    }
    .market-header{
        font-size: 50px;
        line-height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        font-weight: 700;
    }
    

  @media (max-width:380px) {
    .market-header{
        font-size: 35px !important;
    }
    .marketing-container h3{
        font-size: 21px;
    }
  }


  @keyframes animate {
      0% {
          opacity: 0;
          transform: rotate(45deg) translate(-20px, -20px);
      }
      50% {
          opacity: 1;
      }
      100% {
          opacity: 0;
          transform: rotate(45deg) translate(20px, 20px);
      }
  }