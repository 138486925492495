@import url('https://fonts.googleapis.com/css?family=Noto+Sans:700');
@import url('https://fonts.googleapis.com/css?family=Lato');
body
{
	margin:0;
	padding:0;
	font-family: Manrope, sans-serif;
}
.err_page
{
	width:100%;
  height:80%;
	margin:4% auto;
	background:#fff;
	text-align:center;
	display:flex;
	align-items: center;
}
.err_page_right
{
	width:100%;
}
.err_page_left
{
		width:100%;
}
.err_page h1
{
	font-family: Manrope, sans-serif;
	font-size:70pt;
	margin:0;
	color:#6400ff;
}
.err_page h4
{	color:#6400ff;
	font-size:14pt;
}
.err_page p
{
	font-size:14pt;
	color: #737373;
}
.err_btn
{
	background:#fff;
	border:2px solid #6400ff;
	padding: 10px 15px;
	border-radius:5px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	cursor:pointer;
	font-size:13pt;
	transition:background 0.5s;
}
.err_btn:hover
{
	background:#6400ff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
	color:#fff;
}
.credit
{
  position:absolute;
  bottom:0;
  right:0;
}
@media screen and (max-width: 800px)
{
  .err_page
  {
     flex-direction:column;  
  }
  .err_page_left img
  {
       width:250px;
      height:auto;
  }
  .err_page h4
  {
    margin:0;
  }
}
/*-----------NOT NEEDED---------*/
@media screen and (max-width: 350px)
{
  .credit
  {
    visibility:hidden;
  }
}