
/* -------------------------------------New Contact Us Page------------------------------------ */

/* Header Banner-Content */  

.ContactHeader-sections{
  margin-top: 75px;
  background-image:url('../../images/OurWork/BackgroundPage.webp');
  background-position: center;
  padding: 24px 50px !important;
  min-height: calc(100vh - 350px) !important;
  display: flex !important;
  align-items: center !important;
  background-position: center !important;
  margin-top: 60px !important;
  height: 70vh;
}
.Contact-banner-content .head-one{
  margin-bottom: -4px;
}
.Contact-banner-content h1{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color:#131022 !important;
}
.Contact-banner-content h1 span{
  position: relative;
  z-index: 1;
  width: max-content;
  font-family: Manrope, sans-serif;
}
.Contact-banner-content p.sub-p{
    font-style: normal;
    line-height: 30px;
    padding-right: 10px;
    margin-bottom: 30px;
    max-width: 615px;
    margin-top: 17px;
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
    color: gray !important;
    font-weight: 100;
    font-size: 18px;
    font-family: "Manrope",sans-serif;
}

.Contact-banner-button{
  display: inline-flex;
  margin-top: 30px;
  margin-left: -18px;
}
.Conact-theme-btn{
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: .04em;
  color: #ffffff;
  margin-left: 18px;
  box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
  border: 1px solid var(--theme-color);
  border-radius: 6px;
  padding: 18px;
  transition: ease-in-out .5s;
  text-decoration: none;
  background-color: #6366f1 !important;
  animation: pulse 5s infinite !important;
  border: 2px solid #fff !important;
  color: #fff !important;
  cursor: pointer;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

   /* Contact Us Page */
   .contact-section{
    padding-top:100px;
    min-height:60vh;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  
  .contact-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .contact-container p{
    font-weight: 500;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
      font-family: Manrope, sans-serif;
      margin-bottom: 10px;
      color: white;
  }
  
  .contact-container h5{
    font-weight: 600;
      color: white;
      font-family: Manrope, sans-serif;
      font-display: swap;
      display: block;
      margin-bottom: 0;
      font-size: 2.3rem;
      line-height: 3rem;
  }
  
  .contact-card{
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    height: 300px;
  }
  
  .contact-card img{
    width: 40px;
    padding-top: 20px;
  }
  
  .contact-card .para1{
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: black;
    padding: 7px 0px;
    margin-bottom: 0;
  }
  
  .contact-card a{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #828282;
  }
  
  .contact-card a:hover{
    color:#0558a9
  }
  
  .contact-card .para2{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #828282;
    width: 90%;
    padding: 7px 0px;
  }
  
  .form-col{
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .Send-button{
    width: 95% !important;
  }
  .contact-us-form{
    width: 75% !important;
  }
  .contact-us-form-row{
     display: flex;
     flex-wrap: wrap;
     margin-left:-15px;
     margin-left: -15px;
     width: 100%;
     row-gap: 24px;
    }
 

/* Contact Us Form */

.form-contact{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-sections{
  background-color: #fafaff;
}

.Contact-banner-col{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.section-title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: var(--black-color);
    margin: 0 0 40px 0;
}
form label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -.01em;
  color: #3D3D3D;
}

@media (max-width:1200px) {
  .ContactHeader-sections{
    height:82vh !important;
  }
}

@media (max-width:992px) {
  .simple-row{
    margin-top: -530px !important;
  }
  .contact-card{
    height: 100% !important;
  }
  .ContactHeader-sections{
    height:100% !important;
  }
}

@media (max-width:768px) {
  .contact_leftImg img{
    width: 80% !important;
    margin-bottom: 30px;
  }

  .form-contact{
    display: flex;
    justify-content:center !important;
    align-items: center !important;
  }
  
}

@media (max-width:480px) {
  .technologies-image .image{
  width: 100% !important;
}
.all_heading{
     font-size:24px !important;
}

.ContactHeader-sections{
  height: 100% !important;
  padding: 67px 7px !important;
}
.Contact-banner-content h1{
      font-size: 27px !important;
      line-height: 36px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
}
.Contact-banner-button{
  display: flex;
  align-items: center;
  justify-content: center;
}
.Conact-theme-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
.Contact-banner-content p.sub-p{
      max-width: 100% !important;
      font-size: 17px;
      line-height: 1.5;
      font-weight: 400;
}
.section-title {
  font-size: 30px;
}

.Send-button{
   display: flex;
   align-items: center;
   justify-content: center;
}
.contact-us-form-row{
  margin-left: 0px !important;
}
}

/* New Contact Sections Css */
   .section-head{
    margin-bottom: 40px;
    text-align: center; 
   }
   .Main-form-Header{
    justify-content: center;
   }

   .section-head span{
    font-weight: 500;
    text-transform: uppercase;
    background: linear-gradient(to right, #005079, #4a94f7, #ea743a, #e35c18, #ff6800);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 14px;
    line-height: 20px;
    font-family: Manrope, sans-serif;
    margin-bottom: 10px;
   }


   .all_heading{
    font-weight: 600;
    color: #232323;
    font-family: Manrope, sans-serif;
    font-display: swap;
    display: block;
    margin-bottom: 0;
    font-size: 2.1rem;
    line-height: 3rem;
    margin-top: 10px;
   }

   .contact_leftImg
   {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .contact_leftImg img {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    width: 77% !important;
    height: 90%;
    object-fit: contain;
   }


   @keyframes floating {
    0% {
      /* Define the starting state of the animation here */
      transform: translateY(0);
    }
    50% {
      /* Define an intermediate state */
      transform: translateY(-20px);
    }
    100% {
      /* Define the ending state */
      transform: translateY(0);
    }
  }
  
   .centerBorderBlock{
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
   }
   .offerAbso.border-inline{
    position: absolute;
    left: 50%;
    height: 100%;
    border: 1px dashed #dbdade;
   }

   .form-contact{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
   }

   .invalid-feedback{
      color: red !important; 
      margin-top: 2px !important;
      font-size: 14px !important;
      line-height: 22px !important;
      font-weight: 400 !important;
      padding: 0px !important;
      font-family: Manrope, sans-serif;
   }
.medium-input {
  padding: 15px 20px !important;
  font-size: 14px !important;
  height: 49px !important;
  border:1px solid #dfdfdf;
  width: 100%;
  max-width: 100% !important;
  resize: none;
  outline: 0;
  color: #828282;
  line-height: inherit;
}
.medium-textarea{
  border: 1px solid #dfdfdf;
  padding: 10px 15px;
  width: 100%;
  margin: 0 0 15px 0;
  max-width: 100%;
  resize: none;
  outline: 0;
  color: #828282;
  line-height: initial;
  height: 100px;
}


.formBtn{
  background: #6366f1 !important;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
  font-size: 12px;
  padding: 10px 12px;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
.btn.btn-medium{
    font-size: 12px;
    padding: 10px 26px;
}

.form-col{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

form input{
  border-radius: unset;
}

form textarea{
  border-radius: unset;
}

@media (max-width:992px) {
  .simple-row{
    margin-top: -530px !important;
  }

}

