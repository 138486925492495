.terminal-btn{
    width: 1.2rem;
    height: 1.2rem;
    opacity: 100!important;
    border-radius: 50%;
    background-color: #dc3545;
}
.terminal-btn2{
    width: 1.2rem;
    height: 1.2rem;
    opacity: 100!important;
    border-radius: 50%;
    background-color: #ffc107;
    margin-left: .5rem!important;
}
.terminal-btn3{
    width: 1.2rem;
    height: 1.2rem;
    opacity: 100!important;
    border-radius: 50%;
    background-color: #198754;
    margin-left: .5rem!important;
}
  
.terminal-header{
    padding:.5rem!important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-width: 2px;
    border-bottom: 2px solid #dee2e6;
}

.senwell-font {
    color: #00ff00;
    font-size: 2px;
    white-space: pre-wrap;
}
