.sn-footer{
    background-color: #131022 !important;
}
#useful-links h5{
    color: #fff;
    line-height: 1.4;
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 600;
    font-family:"Manrope", sans-serif;
}
 .nav-link{
   color: rgba(255,255,255,.85) !important; 
   font-weight: 400;
   font-size: 14px !important;
   padding-top: 0.25rem!important;
   padding-bottom: 0.5rem!important;
   padding-right: 0!important;
   padding-left: 0!important;
   display: inline-block !important;
   font-family:"Manrope", sans-serif;
}
.nav-link-tech{
    color: rgba(255,255,255,.85) !important; 
   font-weight: 400;
   font-size: 14px !important;
   padding-top: 0.25rem!important;
   padding-bottom: 0.5rem!important;
   padding-right: 0!important;
   padding-left: 0!important;
   display: inline-block !important;
   font-family:"Manrope", sans-serif;
   text-decoration: none;
   border: none;
   background-color: rgb(21,20,36);
   background: none;
}

.footer-address{
    color: rgba(255,255,255,.85);
}
.footer-address-span{
     color: rgba(255,255,255,.85);
     margin-left: 5px;
     font-size: 14px !important;
}

.footer-address-span  a{
    color: rgba(255,255,255,.85);
     margin-left: 5px;
     font-size: 14px !important;
     cursor: pointer; 
}
.footer-address-span  a:hover{
    color: rgba(255,255,255,.85); 
}


.Touch-btn-outline-secondary{
    color: rgba(255,255,255,.85);
    border-color: rgba(255,255,255,.25) !important;
    font-size: 20px !important;
    width: 2.75rem !important;
    height: 2.75rem !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.touch-icon{
    width: 2.75rem;
    height: 2.75rem;
    padding: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    font-weight: 600;
    line-height: 1.6;
    border-radius: 0.375rem;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    user-select: none;
    background-color: transparent !important;
    border: 1px solid transparent ;
}
.nav-link{
    color: rgba(255,255,255,.85) !important;
    
}
.text-light{
    font-size: .75rem!important;
}
.Get-In-Touch{
    margin-top: 0;
    color: #fff;
    margin-bottom: 1rem !important;
    font-size: 1rem;
    font-weight: 700;
}


/* Social NetWork css */

.Sn-Social{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.We-Social{
    font-size: calc(1.375rem + 1.5vw);
    margin-top: 0;
    font-weight: 600;
    line-height: 1.3;
    color: #131022;
}

.We-Are-Social{
    width: 216px;
    height: 100%;
}
.button-lg{
    width: 3.25rem !important;
    height: 3.25rem !important; 
}
.f-bg{
    background-color: #1877f2!important;
    color: #fff!important;
}


