/* Header Banner-Content */  
.DevopsHeader-sections{
  background-image:url('../../../images/OurWork/BackgroundPage.webp');
  background-position: center;
  padding: 73px 50px !important;
  min-height: calc(100vh - 350px) !important;
  display: flex !important;
  align-items: center !important;
  background-position: center !important;
  margin-top: 60px !important;
  height: 90vh !important;
}

.Devops-banner-content h1{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color:#131022 !important;
}
.Devops-banner-content h1 span{
  position: relative;
  z-index: 1;
  width: max-content;
  font-family: "Manrope", sans-serif;
}
.Devops-banner-content p.sub-p{
    font-style: normal;
    line-height: 30px;
    padding-right: 10px;
    margin-bottom: 30px;
    max-width: 615px;
    margin-top: 17px;
    text-align: justify;
    word-spacing: -0.1em;
    hyphens: auto;
    color: gray !important;
    font-weight: 100;
    font-size: 18px;
    font-family: "Manrope",sans-serif;
}

.Devop-banner-button{
  display: inline-flex;
  margin-top: 30px;
  margin-left: -18px;
}
.Devops-theme-btn{
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    margin-left: 18px;
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
    background-color: #6366f1 !important;
    animation: pulse 5s infinite !important;
    border: 2px solid #fff !important;
    color: #fff !important;
}

.Devops-theme-btn .them-btn-alt {
  border: 1px solid #ffff;
  box-shadow: 0 30px 40px -18px rgba(255,255,255,.3);
  border-radius: 6px;
  background: transparent;
}

.devops-h2{
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: black;
  margin: 0 0 30px 0;
}

.devops-p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color:#4c5a67;
    margin: 0 auto 60px;
    width: 100%;
    padding: 0 15px;
}

.devops-h4{
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 50px;
  color: black;
  margin: 0 0 30px 0;
}

.devops-p2{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #192734;
  text-align: justify;
  width: 90%;
}

.lifecycle-image{
  display: flex;
  justify-content: center;
  align-items: center;
}

.web-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Devops-banner-col{
    display: flex;
    justify-content:flex-start;
    align-items: center;
}

.web-container .Dev-header{
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 50px;
  color: gray;
  margin: 0 0 30px 0;
}

.web-container .Devops-web-para{
        font-family:"Manrope, sans-serif";
        font-style: normal;
        width: 80%;
        font-weight: 400;
        font-size: 19px;
        line-height: 30px;
        text-align: justify;
        word-spacing: -0.1em;
        hyphens: auto;
}

.devops-image{
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
   }

   .devops-image .image{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
       }

   @media (max-width:992px) {
      .devops-image .image{
        width: 70% !important;
      }

      .lifecycle-image img{
        width: 100% !important;
      }
      .DevopsHeader-sections{
        height:100% !important; 
      }
   }

   @media (max-width:768px) {
    .lifecycle-image img{
      width: 80% !important;
    }
 }

   @media (max-width:680px) {
      .devops-image .image{
      width: 80% !important;
    }
 }

   @media (max-width:480px) {
      .devops-image .image{
      width: 100% !important;
    }
    .Devops-theme-btn{
      margin-left: 0px !important;
    }
    .DevopsHeader-sections{
      height: 100% !important;
      padding: 67px 7px !important;
    }
    .Devops-banner-content h1{
        font-size: 23px !important;
        line-height: 29px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Devops-banner-content p.sub-p{
        line-height: 25px;
        font-size: 15px;
        font-weight: 400;
    }
    .Devop-banner-button{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .Devops-theme-btn{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .web-container .Devops-web-para{
      font-size: 17px !important;
      line-height: 29px;
    }
    .devops-h2{
      font-size: 33px;
    }
    .devops-p2{
      width: 100% !important;
      text-align: justify;
      word-spacing: -0.1em;
      hyphens: auto;
      padding: 0px 30px;
    }
    .devops-h4{
      font-size: 19px;
      line-height: 29px;
      text-align: center;
    }
 }

 @media (max-width:380px) {
  .service-widget{
  height: 100% !important;
}
}


 