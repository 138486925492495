* {
  font-family: Manrope, sans-serif;
}

body::-webkit-scrollbar {
  width: 0px !important;
}

img {
  -webkit-user-drag: none;
  -moz-user-drag: none;
  user-select: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.container {
  position: absolute;
  top: 10%;
  left: 23%;
  width: 50%;
}

.text {
  display: inline;
  width: 100%;
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

.main-wrapper {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 1rem 1rem 2rem 1rem;
}

.main-wrapper .serviceBox {
  cursor: pointer;
  position: relative;
  /* width: 150px;
  height: 130px; */
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(117, 114, 114, 0.19), 0 6px 6px rgba(160, 148, 148, 0.23);
  border-bottom: 5px solid #fff;
  box-sizing: border-box;
  /* background: linear-gradient(90deg, hsla(200, 69%, 50%, 1) 24%, hsla(216, 62%, 39%, 1) 83%); */
}

@media screen and (min-width: 1000px) {
  .main-wrapper .serviceBox {
    width: 13.5rem;
  }
}

@media screen and (min-width: 700px) {
  .main-wrapper .serviceBox {
    width: 10rem;
    height: 8rem;
  }
}

@media screen and (max-width: 700px) {
  .main-wrapper .serviceBox {
    width: 10rem;
  }
}

.main-wrapper .serviceBox:hover {
  /* background: linear-gradient(90deg, hsla(200, 69%, 50%, 1) 24%, hsla(216, 62%, 39%, 1) 83%); */
  /* border:1px solid #fff; */
  border-bottom: 5px solid #2156a0;
}

.main-wrapper .serviceBox .icon {
  position: absolute;
  top: 20px;
  left: calc(50% - 25px);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: #2156a0;
}

.main-wrapper .serviceBox:hover .icon {
  left: calc(50% - 25px);
  width: 60px;
  height: 60px;
}

.icon-wrapper {
  border-radius: 50%;
}

.main-wrapper .serviceBox .icon i {
  font-size: 3em;
  /* color: #fff; */
}

.main-wrapper .serviceBox:hover .icon i {
  font-size: 2em;
}

.main-wrapper .serviceBox .content {
  position: relative;
  padding: 20px;
  /* color: #fff;  */
  text-align: center;
  margin-top: 65px;
  z-index: 1;
  transform: scale(0);
  box-sizing: border-box;
  height: 80px;
}

.main-wrapper .serviceBox .content {
  transform: scale(1);
}

.main-wrapper .serviceBox .content h2 {
  margin-top: 20px;
  margin-bottom: 5px;
}

.main-wrapper .serviceBox .content p {
  font-weight: 300;
  line-height: 1.5em;
}

.MuiContainer-maxWidthLg {
  max-width: -webkit-fill-available !important;
}

/* Card CSS : start */
.m-card {
  height: 100%;
  padding: 0.5rem;
  width: 250px;
  position: relative;
  margin-block: 0.3rem;
}

.m-card-img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.m-card-img img {
  width: 100%;
  height: 100%;
  transition: 1s;
  object-fit: cover;
}

.m-card-content {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.574);
  display: grid;
  place-content: center;
  font-size: 3rem;
  transition: 0.5s;
  color: white;
}

.m-card-name {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 0.5rem;
  white-space: nowrap;
  margin-bottom: 0;
}

.m-card-designation {
  font-size: 1rem;
  text-align: center;
}

.m-card:hover .m-card-content {
  top: 0;
  cursor: pointer;
}

.m-card:hover img {
  transform: scale(1.1);
}

.m-card a {
  display: block;
}


/* Card CSS : end */