/* Header Banner-Content */
.Technologyheader-sections {
  background-image: url('../../images/OurWork/BackgroundPage.webp');
  background-position: center;
  padding: 50px 50px !important;
  min-height: calc(100vh - 350px) !important;
  display: flex !important;
  align-items: center !important;
  background-position: center !important;
  margin-top: 60px !important;
  height: 70vh;
}

.Technology-banner-content .head-one {
  margin-bottom: -4px;
}

.Technology-banner-content h1 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;
  color: #131022 !important;
}

.Technology-banner-content h1 span {
  position: relative;
  z-index: 1;
  width: max-content;
  font-family: "Manrope", sans-serif;
}

.Technology-banner-content p.sub-p {
  font-style: normal;
  line-height: 30px;
  padding-right: 10px;
  margin-bottom: 30px;
  max-width: 615px;
  margin-top: 17px;
  text-align: justify;
  word-spacing: -0.1em;
  hyphens: auto;
  color: gray !important;
  font-weight: 100;
  font-size: 18px;
  font-family: "Manrope", sans-serif;
}

.Technology-banner-button {
  display: inline-flex;
  margin-top: 30px;
  margin-left: -18px;
}

.Tech-theme-btn {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: .04em;
    color: #ffffff;
    margin-left: 18px;
    box-shadow: 0 30px 40px -18px rgba(36,144,239,.3);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    padding: 18px;
    transition: ease-in-out .5s;
    text-decoration: none;
    background-color: #6366f1 !important;
    animation: pulse 5s infinite !important;
    border: 2px solid #fff !important;
    color: #fff !important;
}

.technologies-image {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.technologies-image .image {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 333px;
}


@media (max-width:992px) {
  .technologies-image.image {
    width: 50% !important;
  }

  .technologies-image .image {
    height: 100% !important;
    width: 70%;
  }
}

@media (max-width:680px) {
  .technologies-image.image {
    width: 80% !important;
  }
}

@media (max-width:550px) {
  .Technology-banner-content h1 {
    font-size: 24px !important;
  }
}

@media (max-width:992px) {
  .Technologyheader-sections {
    height: 100% !important;
  }
}

@media (max-width:480px) {
  .technologies-image .image {
    width: 100% !important;
  }
  .Technologyheader-sections {
    height: 100% !important;
    padding: 67px 7px !important;
  }
  .Technology-banner-content h1{
      font-size: 27px !important;
      line-height: 36px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }
  .Technology-banner-content p.sub-p{
      max-width: 100% !important;
      font-size: 17px;
      line-height: 1.5;
      font-weight: 400;
  }
  .Technology-banner-button{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Tech-theme-btn{
     display: flex;
     align-items: center;
     justify-content: center;
  }
  
}

@media (max-width:360px) {
  .Technologyheader-sections {
    width: 100% !important;
  }
}