.title1 {
  margin-left: 20px;
}
.OurContenter {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  background-image: url('mockup.jpg');
  background-repeat: no-repeat;
  /* background-repeat: no-repeat; */
  background-attachment: fixed;
  background-position: center;
  justify-content: center;
  background-size: 50% 50%;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .each-fade {
    width: 100%;
    height: 62vh;
  }
}

@media only screen and (max-width: 700px) {
  .each-fade {
    width: 100%;
    height: 62vh;
  }
}

.OurContent2 {
  width: 100%;
  height: 50px;
  margin-bottom: 50px;
  margin-left: 80px;
  margin-top: 20px;
  color: white;
}
.cardUR {
  margin-left: 10px;

  margin-top: 80px;
  position: relative;

  width: 300px;
  height: 150px;
  border-radius: 10px;
  /* box-shadow: 0px 5px 20px rgba(241, 237, 237, 0.5); */
  transition: 0.3s;
  padding: 10px;

  /* cursor: pointer; */
}
/* .cardUR:hover {
  height: 350px;
} */
.ImagUR1 {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateY(-80px);
  /* color: white; */

  /* / padding-left: 20px; / */
}
.img2 {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0.5);
}
.contentUR {
  padding: 10px 20px;
  text-align: center;
  transform: translateY(-200px);
  opacity: 0;
  transition: 0.3s;
  color: white;
}
/* .cardUR:hover > .contentUR {
  opacity: 1;
  transform: translateY(-250px);
} */
.cardUR {
  margin-left: 10px;

  margin-top: 80px;
  position: relative;

  width: 300px;
  height: 150px;
  border-radius: 10px;
  /* box-shadow: 0px 5px 20px rgba(241, 237, 237, 0.5); */
  transition: 0.3s;
  padding: 10px;

  /* cursor: pointer; */
}
/* .cardUR:hover{
     height: 350px; */

/* } */
.ImagUR1 {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateY(-80px);
  /* color: white; */

  /* / padding-left: 20px; / */
}
.img2 {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0.5);
}
.contentUR {
  padding: 10px 20px;
  text-align: center;
  transform: translateY(-200px);
  opacity: 0;
  transition: 0.3s;
  color: white;
}
.cardUR:hover > .contentUR {
  opacity: 1;
  transform: translateY(-250px);
}
