/* DiwaliSlider.css */

.slider-container {
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden;
    margin-top: 92px !important;
}

.particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .slider-container {
        height: 187px;
        margin-top: 89px !important;
    }
}