
.App1 {
  width: 100%;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 35px;
}

.card-top{
    display: flex;
    justify-content: space-between;
}

.card-top > img {
  object-fit: cover;
  background: black;
  width: 90%;
  height: 100%;
  margin: auto;
}
.industries-header{
    color: #3b3663 !important;
    line-height: 1.4 !important;
    letter-spacing: -0.4px !important;
    font-size: 3rem !important;
    font-weight: 500 !important;
    font-family: Manrope, sans-serif;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin-bottom: 0 !important;
    text-align: center !important;
}
.indurstry-para{
   font-size: 20px;
   margin-top: 15px;
   color: gray;
}


@media (max-width:380px) {
.industries-header{
    font-size: 33px !important;
}
}


@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}