.ProductDev{
    margin-top:73px;
}
.main-banner{
    position: relative;
    padding: 150px 0px;
    z-index: 1;
    background:transparent url('../../../images//SoftwareDev/HomeB.png');
}

.main-banner .Product-title{
    font-size: 54px;
    color: lightgray;
    line-height: 60px;
    font-weight: 700;
}

.main-banner .content-para{
    color: lightgray;
    line-height: 1.9;
    font-size: 17px;
}

.section-services{
    background-color: #f8f8f8;
    padding: 70px 0;
}

.section-head{
    margin: 0 auto 20px;
    max-width: 900px;
    line-height: 1.2;
    color: #0b2e58;
    font-weight: 400;
}
.section-para{
    font-size: 18px;
    max-width: 650px;
    margin: 0 auto;
    color: #333;
}

.work-itemProduct{
    background: #fff;
    border: 1px solid #e1e1e1;
    padding: 30px;
    height: 436.4px;
    width: 100%;
}
.work-itemProduct .icon{
    margin-bottom: 20px;
    display: flex;
}
.icon img{
    width: 60px;
    height: auto;
}

.work-itemProduct h3{
    font-size: 20px;
    margin-bottom: 22px;
    font-weight: 600;
    line-height: 30px;
}
.work-itemProduct p{
    line-height: 24px;
    margin-top: 13px;
    color: #333;
}
.work-grid{
   margin:0rem 0rem 2rem; 
}
.leftimg{
    width: 100%;
    border-radius: 0.5rem;
}
.design-and-content-header{
    font-size: 36px !important;
    line-height: 44px !important;
    margin-bottom: 32px !important;
    font-weight: 700 !important;
}
.design-and-content-para{
    margin-bottom: 32px;
    font-size: 17px;
}
.section-padding{
    padding:70px 0;
}
.section-padding-tech{
    padding:70px 0;
    background: #f8f8f8;
}
.section-padding-product{
    padding:70px 0;
    background: #f3f6ff;
}


.section-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #192734;
    margin: 0 0 30px 0;
}


/* Home Animations CSS */
.shape2 {
    position: absolute;
    z-index: -1;
    top: 60%;
    left: 15%;
  }

  .shape3{
    position: absolute;
    bottom: 32%;
    z-index: -1;
  }
  .shape3{
    left: 25%;
    animation: animationFramesOne 15s linear infinite;
  }
  @keyframes animationFramesOne {
    0% {
      -webkit-transform: translate(0, 0) rotate(0deg);
      transform: translate(0, 0) rotate(0deg);
    }
    20% {
      -webkit-transform: translate(73px, -1px) rotate(36deg);
      transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
      -webkit-transform: translate(141px, 72px) rotate(72deg);
      transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
      -webkit-transform: translate(83px, 122px) rotate(108deg);
      transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
      -webkit-transform: translate(-40px, 72px) rotate(144deg);
      transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
      -webkit-transform: translate(0, 0) rotate(0deg);
      transform: translate(0, 0) rotate(0deg);
    }
  }
  .shape4{
    position: absolute;
    bottom: 28%;
    z-index: -1;
  }
  .shape4{
    right: 25%;
    animation: animationFramesOne 20s linear infinite;
  } 
  .shape5{
    position: absolute;
    z-index: -1;
    right: 5%;
    top: 10%;
    animation: movebounce 5s linear infinite;
    opacity: 0.2;
  }
  .shape6{
     position: absolute;
     z-index: -1;
     top: 40%;
     right: 10%;
     animation: rotateme 20s infinite linear;
  }

  .shape7{
    position: absolute;
    top: 15%;
    left:25%;
    z-index: -1;
    animation: animationFramesOne 20s linear infinite;
  }
  .shape8{
    position: absolute;
    top: 15%;
    z-index: -1;
    right: 10%;
    animation: rotateme 20s infinite linear;
  }
  @keyframes movebounce {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .rotateme {
    animation-name: rotate; 
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }

/* ==== Industry section ==== */
.industry-section .nav-tabs {
    justify-content: center !important;
  }
  .industry-section .nav-item .nav-link {
    font-size: 16px !important;
    line-height: 30px !important;
    color: #828282 !important;
    padding: 0.5rem 1rem !important;
    transition: color 0.3s linear !important;
    cursor: pointer !important;
    border: none !important;
  }
  .industry-section .nav-item .nav-link.active,
  .industry-section .nav-item .nav-link:hover {
    color: #343434 !important ;
    background-color: transparent !important;
  }
  .industry-section .tab-content {
    padding: 50px 0 30px !important;
  }
  .industry-section .tab-content .tab-pane {
    border: none !important;
  }
  .industry-section .tab-content ul {
    list-style: none !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-column-gap: 10px !important;
    column-gap: 10px !important;
    padding-left: 0 !important;
  }
  .industry-section .tab-content ul li {
    background: transparent;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    max-width: 108px;
    padding: 18px 13px;
    flex-wrap: wrap;
  }
  .industry-section .tab-content ul li:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #fff;
  }
  .industry-section .tab-content ul li img {
    max-width: 60px !important;
  }
  .industry-section .tab-content ul li span {
    display: block !important;
    margin: auto !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    padding-top: 5px !important;
    color: #343434 !important ;
  }
  
  @media (max-width: 1366px) {
    .industry-section .tab-content ul li:last-child,
    .industry-section .tab-content ul li:nth-child(7n) {
      border-right: 0 !important;
    }
  }
  @media (max-width: 1200px) {
    .industry-section .tab-content ul li:last-child,
    .industry-section .tab-content ul li:nth-child(6n) {
      border-right: 0 !important;
    }
  }


  /* ==== Insights Section ===== */
.insight-section .title {
    position: relative;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 2rem;
  }
  .insight-section .title::before {
    content: "";
    border-top: 1px solid #d8d8d8;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .insight-section .title span {
    background-color: #f3f6ff;
    z-index: 3;
    position: relative;
    padding: 0 20px;
  }
 
  .insight-section .insight-card {
    background-color: #fff;
    z-index: 3;
    position: relative;
    padding-bottom: 3.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .insight-section .insight-card .tagline {
    color: #1b72e1;
    font-size: 14px;
  }
  .insight-section .insight-card .card-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-left: 13px;
  }
  .insight-section .insight-card .card-title a {
    text-decoration: none;
    color: #343434;
    font-size: 1.4rem;
  }
  .insight-section .insight-card .des {
    font-size: 15px;
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    line-height: 22px;
    overflow: hidden;
    color: #585c7b;
    padding:5px 10px;
  }
  .insight-section .insight-card .footer {
    position: absolute;
    bottom: 0.5rem;
    color: #767676;
    font-size: 14px;
  }
  .insight-section .viewmore {
    padding-top: 60px;
  }
  .insight-section .viewmore .viewmore-btn {
    text-decoration: none;
    font-size: 14px;
    color: #343434;
    border-radius: 0;
    padding: 8px 27px;
    position: relative;
    background-color: transparent;
    z-index: 2;
    border: 1px solid #343434;
    transition: all 0.3s linear;
    box-shadow: 0 0 !important;
  }
  .insight-section .viewmore .viewmore-btn:hover {
    background: #343434;
    color: #fff;
  }
  
  /* ==== Industry section ==== */
  .industry-section .nav-tabs {
    justify-content: center !important;
  }
  .industry-section .nav-item .nav-link {
    font-size: 16px !important;
    line-height: 30px !important;
    color: #828282 !important;
    padding: 0.5rem 1rem !important;
    transition: color 0.3s linear !important;
    cursor: pointer !important;
    border: none !important;
  }
  .industry-section .nav-item .nav-link.active,
  .industry-section .nav-item .nav-link:hover {
    color: #343434 !important ;
    background-color: transparent !important;
  }
  .industry-section .tab-content {
    padding: 50px 0 30px !important;
  }
  .industry-section .tab-content .tab-pane {
    border: none !important;
  }
  .industry-section .tab-content ul {
    list-style: none !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-column-gap: 10px !important;
    column-gap: 10px !important;
    padding-left: 0 !important;
  }
  .industry-section .tab-content ul li img {
    max-width: 60px !important;
  }
  .industry-section .tab-content ul li span {
    display: block !important;
    margin: auto !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    padding-top: 5px !important;
    color: #343434 !important ;
  }

  @media screen and (max-width: 768px){
    .ProductDev{
      margin-top: 0;
    }
    .main-banner .Product-title{
      font-size: 34px;
    line-height: 42px;
    font-weight: 600;
    color: lightgray;
    margin-bottom: 25px;
    }
    .main-banner .content-para {
      line-height: 1.8;
      font-size: 16px;
      color: lightgray;
    }
  }

  /* Tablets Size Media Query */
@media screen and (min-width: 769px) and (max-width: 1024px){
  .CustomeDev {
      margin-top: 65px;
  }

}

  

  
  