/* Overall styling for the news section container */
.blog-sections {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Styling for the first news section */
  .first-news-section .news-img {
    width: 100%;
    height: 100%;
  }
  
  .first-news-section .news-img img {
    width: 100%;
    height: auto;
  }
  
  /* Image center class for better layout control */
  .image-center {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }
  
  /* Restrict image size and maintain its responsiveness */
  .image {
    max-width: 1200px;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add rounded corners for a modern look */
  }
  
  /* Banner image with responsiveness */
  .bannerimg {
    width: 100%;
    max-width: 1200px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add some shadow for a more appealing look */
    object-fit: cover; /* Ensure the image doesn't stretch */
  }
  
  /* News article container styling */
  .news-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 20px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 12px;
  }
  
  /* Heading styling */
  .news-container h1 {
    /* font-size: 2.5rem; */
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* Subtitle styling */
  .news-container h3 {
    font-size: 1.3rem;
    font-weight: 500;
    max-width: 1200px;
    /* color: #030303; */
    text-align: center;
    margin-bottom: 15px;
  }
  
  /* Styling for the article body */
  .news-container p {
    font-size: 1.1rem;
    line-height: 1.7;
    /* color: #000000; */
    max-width: 1200px;
    text-align: justify;
    margin-bottom: 20px;
    word-wrap: break-word;
  }
  
  /* Add responsiveness */
  @media (max-width: 768px) {
    .news-container {
      padding: 10px 20px;
      margin: 0;
    }
  
    .news-container h1 {
      font-size: 2rem;
    }
  
    .news-container h3 {
      font-size: 1.1rem;
    }
  
    .news-container p {
      font-size: 1rem;
    }
  }
  
    /* Add responsiveness */
    @media (min-width: 768px) {
      .news-container {
        padding: 10px 20px;
        margin: 0;
        width: 100%;
      }
    }

    .news-container .container{
        position: static !important;
        border: none;
    }


    