@charset "UTF-8";

/*Header Bottom Area*/
.header-bottom {
  background: #fff;
  position: relative;
  box-shadow: 0 3.4px 2.7px rgb(0 0 0 / 1%), 0 8.7px 6.9px rgb(0 0 0 / 1%),
    0 17.7px 14.2px rgb(0 0 0 / 1%), 0 36.5px 29.2px rgb(0 0 0 / 1%),
    0 100px 80px rgb(0 0 0 / 2%);
}

.App {
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 1%;
}

.App-logo {
  height: 80px;
  padding-top: 5px;
  position: relative;
  margin-right: 5px;
  pointer-events: none;
  animation: rotate360 10s linear infinite;
  transform-origin: center;
}







.app-logo {
  height: 7px;
  position: absolute;
  top: 46.9%;
  left: 3.4%;

}

@media screen and (max-width:1200px) {
  .app-logo {
    height: 8px;
    position: absolute;
    top: 46.9%;
    left: 3.8%;

  }
}

@media screen and (max-width:1000px) {
  .app-logo {
    height: 8px;
    position: absolute;
    top: 46.9%;
    left: 4%;

  }
}

@media screen and (max-width:500px) {
  .app-logo {
    height: 8px;
    position: absolute;
    top: 46.9%;
    left: 4.7%;

  }
}

@media screen and (max-width:350px) {
  .app-logo {
    height: 8px;
    position: absolute;
    top: 48.5%;
    left: 5.5%;

  }
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.sticky-header.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background: #333;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  animation-name: fadeInDown;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  border-bottom: 0;
}

.main-menu {
  position: relative;
}

.main-menu nav>ul>li {
  position: relative;
  display: inline-block;
  color: #fff;
}

.main-menu nav>ul>li>span {
  color: #fff;
}

.main-menu nav>ul>.has-dropdown {
  margin-right: 5px;
}

.main-menu nav>ul>.has-dropdown:last-child {
  margin-right: 0;
}

.main-menu nav>ul>.has-megaitem {
  position: static;
}

.main-menu nav>ul>li>button {
  display: block;
  line-height: 24px;
  padding: 15px 15px;
  font-size: 16px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  font-weight: 500;
  color: #2a2a2a;
}

.main-menu nav>ul>li a:hover {
  background: none !important;
}


.sub-menu>li>a,
.mega-menu-sub>li>a {
  color: #7d828c !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  transition: .5s;
  padding-left: 0;
}

.sub-menu>li>a:hover,
.mega-menu-sub>li> :hover {
  color: white;
}

.sub-menu>li>a:hover::after,
.mega-menu-sub>li> :hover::after {
  transform: translate(-10px, -50%);
  visibility: visible;
  opacity: 1;
}

.sub-menu {
  position: absolute;
  min-width: 275px;
  padding: 25px 20px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-20deg);
  transform-origin: 0 0 0;
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 99;
  top: 56px;
  text-align: left;
}


/* New start*/

.sub-menu-new {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 15px 30px rgba(18, 19, 20, 0.2);
  color: black;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  right: -160%;
  top: 50px;
}

.inner-sub-menu {
  width: 15vw;
  padding: 20px;
  display: flex;
  flex-direction: column;
}


.inner-sub-menu span {
  font-size: 16px;
  font-weight: 600;
  color: #222;
}

.inner-sub-menu a {
  padding: 1.1rem 0.5rem;
  text-shadow: none;
  color: #7d828c;
  line-height: 1.35;
  display: block;
  font-size: 14px;
  transition: .5s;
}

.inner-sub-menu a:hover {
  color: #0084ff !important;
  transform: translateX(5px);
}

.main-menu nav>ul>li:hover .sub-menu-new,

.main-menu nav>ul>li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  z-index: 100;
  position: absolute;
  top: 100%;
}

/* End New start*/

.mega-menu {
  position: absolute;
  min-width: 60%;
  width: 60%;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-20deg);
  transform-origin: 0 0 0;
  top: 0;
  left: 35%;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.mega-menu-inner {
  display: flex;
  justify-content: space-between;
}

.mega-menu-item-title {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 13px;
  color: #333;
}

.mega-menu-item-title:hover {
  color: #261146;
}

.mega-menu-sub>li {
  display: block;
  /* display: flex; */
  padding-top: 20px;
}

.mega-menu-sub {
  font-family: "Poppins", sans-serif;
  color: #000;
}

.mega-menu-inner {
  margin: 0;
  padding: 0;
}

.main-menu nav>ul>li:hover .sub-menu,

.main-menu nav>ul>li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.menu-banner {
  margin-top: 50px;
}

.menu-banner>a {
  display: block;
  width: 100%;
}

.menu-banner img {
  width: 100%;
}

.mobile-header-wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
}

.mobile-menu {
  border: 1px solid #000;
  padding: 5px;
  display: block;
  border-radius: 4px;
}

.mobile-menu-dash {
  display: block;
  width: 30px;
  height: 2px;
  background: #000;
  margin-top: 5px;
  margin-bottom: 5px;
}

/*****************************
Layout - Offcanvas
*****************************/
.offcanvas {
  z-index: 999999;
  position: fixed;
  top: 0;
  overflow: auto;
  height: 100vh;
  padding: 20px;
  transition: all 0.3s ease 0s;
  transform: translateX(100%);
  box-shadow: none;
  background: #212121;
  color: #eaeaea;
  width: 300px;
  overflow-x: hidden;
  box-shadow: 0 3.4px 2.7px rgb(0 0 0 / 1%), 0 8.7px 6.9px rgb(0 0 0 / 1%),
    0 17.7px 14.2px rgb(0 0 0 / 1%), 0 36.5px 29.2px rgb(0 0 0 / 1%),
    0 100px 80px rgb(0 0 0 / 2%);
}

.offcanvas-leftside {
  left: 0%;
  transform: translateX(-100%);
}

.offcanvas-rightside {
  right: 0%;
  transform: translateX(100%);
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-header {
  margin-bottom: 30px;
}

.offcanvas-close {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}

.offcanvas-close:hover {
  color: #261146;
  border-color: #261146;
}

.offcanvas-title {
  margin-bottom: 40px;
}

/*mobile-menu Top Area*/
.mobile-menu-top {
  font-size: 13px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.mobile-menu-user-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  justify-content: center;
}

.mobile-menu-user-menu>li {
  margin-right: 40px;
  position: relative;
}

.mobile-menu-user-menu>li:last-child {
  margin-right: 0;
}

.mobile-menu-user-menu>li::after {
  position: absolute;
  content: "|";
  top: 50%;
  transform: translateY(-50%);
  right: calc(0% - 20px);
}

.mobile-menu-user-menu>li:last-child::after {
  display: none;
}

.mobile-menu-user-menu>li>a {
  display: inline-block;
  line-height: 46px;
  color: #666 !important;
}

.mobile-menu-user-menu>li>a:hover {
  color: #261146;
}

/* .spanFont > span {
  font-family: "Poppins";
  font-size: 1.1rem;
} */

.has-mobile-user-dropdown {
  position: relative;
  padding-right: 15px;
}

.has-mobile-user-dropdown::before {
  content: "";
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-weight: 400;
}

.mobile-user-sub-menu {
  position: absolute;
  top: calc(100%);
  left: 50%;
  transform: translate(-50%, -30px);
  padding: 10px 15px;
  width: 160px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}

.mobile-user-sub-menu>li {
  display: block;
  border-bottom: 1px solid #eee;
  /* padding: 5px 0; */
}

.mobile-user-sub-menu>li:last-child {
  border-bottom: none;
}

.mobile-user-sub-menu>li>a {
  position: relative;
  color: #666;
  display: flex;
  align-items: center;
}

.mobile-user-sub-menu>li>a:hover {
  color: #261146;
}

.user-sub-menu-link-icon {
  padding-right: 5px;
}

.has-mobile-user-dropdown:hover .mobile-user-sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}

/*Mobile Menu Center Area*/
.mobile-menu-customer-support {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Raleway", sans-serif;
}

.mobile-menu-customer-support-icon {
  max-width: 50px;
  overflow: hidden;
  margin-right: 30px;
}

.mobile-menu-customer-support-icon img {
  width: 100%;
}

.mobile-menu-customer-support-text-phone {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  transition: all 0.3s ease 0s;
}

.mobile-menu-customer-support-text-phone:hover {
  color: #261146;
}

.mobile-menu-customer-support-text {
  font-size: 14px;
  margin: 20px 0;
}

.mobile-action-icon {
  display: flex;
  justify-content: center;
}

.mobile-action-icon-item {
  margin-right: 40px;
}

.mobile-action-icon-item:last-child {
  margin-right: 0;
}

.mobile-action-icon-link {
  position: relative;
  font-size: 27px;
  color: #333;
  transition: all 0.3s ease 0s;
}

.mobile-action-icon-link:hover {
  color: #261146;
}

.mobile-action-icon-item-count {
  position: absolute;
  top: 0;
  right: calc(0% - 10px);
  font-size: 11px;
  display: inline-block;
  background: #333;
  color: #fff;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease 0s;
}

.mobile-action-icon-link:hover .mobile-action-icon-item-count {
  background: #261146;
}

/*Mobile Menu Bottom Area*/
.mobile-menu-bottom {
  position: fixed;
  bottom: 5%;
}

.offcanvas-menu {
  margin: 20px 0;
}

.offcanvas-menu li {
  position: relative;
  padding: 5px 5px;
}

.offcanvas-menu>ul>li {
  border-bottom: 1px solid transparent;
}

.offcanvas-menu>ul>li:last-child {
  border-bottom: none;
}

.offcanvas-menu li a {
  display: block;
  color: #eaeaea;
  text-decoration: none;
}

.offcanvas-menu li a:hover {
  color: #ddd;
}

.offcanvas-menu li .mobile-sub-menu {
  display: none;
}

.offcanvas-menu-expand::after {
  content: "";
  position: absolute;
  font-family: "FontAwesome";
  right: 0;
  transition: all 0.3s ease;
  color: #666;
}

.offcanvas-menu .active>.offcanvas-menu-expand:last-child,
.sub-menu>.active .offcanvas-menu-expand:last-child {
  border-bottom: none;
}

.offcanvas-menu .active>.offcanvas-menu-expand::after,
.sub-menu>.active .offcanvas-menu-expand::after {
  color: #777;
  content: "";
}

.mobile-menu-email {
  color: #999;
  text-align: center;
  display: block;
}

.mobile-menu-email:hover {
  color: #261146;
}

.mobile-menu-social {
  margin-top: 25px;
  text-align: center;
}

.mobile-menu-social>li {
  display: inline-block;
  margin-right: 13px;
}

.mobile-menu-social>li:last-child {
  margin-right: 0px;
}

.mobile-menu-social>li>a {
  display: inline-block;
  font-size: 14px;
  padding: 0;
  /* line-height: 30px; */
  width: 30px;
  height: 15px;
  border-radius: 100%;
  text-align: center;
  color: #fff !important;
}

.mobile-menu-social>li>a:hover {
  opacity: 0.7;
}

.mobile-menu-social>li>a.facebook {
  background: #4867aa;
}

.mobile-menu-social>li>a.twitter {
  background: #1da1f2;
}

.mobile-menu-social>li>a.youtube {
  background: #fe0000;
}

.mobile-menu-social>li>a.pinterest {
  background: #bd081b;
}

.mobile-menu-social>li>a.instagram {
  background: #b23a94;
}

.offcanvas-cart-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.offcanvas-cart-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-cart-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-cart-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #eee;
  margin-right: 20px;
}

.offcanvas-cart-image {
  width: 100%;
}

.offcanvas-cart-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-cart-item-details {
  font-family: "Raleway", sans-serif;
}

.offcanvas-cart-total-price {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}

.offcanvas-cart-total-price-text {
  font-weight: 700;
}

.offcanvas-cart-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-cart-action-button-link {
  display: block;
  background: #000;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
}

.offcanvas-cart-action-button-link:hover {
  background: #261146;
  color: #fff;
}

.offcanvas-wishlist-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.offcanvas-wishlist-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-wishlist-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-wishlist-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #eee;
  margin-right: 20px;
}

.offcanvas-wishlist-image {
  width: 100%;
}

.offcanvas-wishlist-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-wishlist-item-details {
  font-family: "Raleway", sans-serif;
}

.offcanvas-wishlist-total-price-text {
  font-weight: 700;
}

.offcanvas-wishlist-action-button {
  margin-top: 40px;
}

.offcanvas-wishlist-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-wishlist-action-button-link {
  display: block;
  background: #000;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
}

.offcanvas-wishlist-action-button-link:hover {
  background: #261146;
  color: #fff;
}